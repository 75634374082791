import React, { Component } from 'react';
import './MissionStatement.css';

class MissionStatement extends Component {
  render() {
    return(
      <div id="studentWork-mission-container">
        <div class="studentWork-mission-content-container">
          <h4>Introduction</h4>
          <h2>Student Work</h2>
          <p>Much of the work we do explores what youth are capable of when given the opportunity to explore their interests and talents. Thus, our projects frequently have students creating a wide array of works from games to choreographies to films to robots. This page on our site is dedicated to showcasing this work and sharing with others what we get to see every day: the incredible things that youth today can make with the right tools and support.</p>
        </div>
      </div>
    );
  }
}

export default MissionStatement;