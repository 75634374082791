import React, { Component } from 'react';
import CteEdHeader from './cteEd-Header';
import CteEdContent from './cteEd-Content';
import './cteEd.css';

export default class CteEd extends Component {
  render() {
    return(
      <div id="cteEd-container">
        <CteEdHeader />
        <CteEdContent />
      </div>
    );
  }
}