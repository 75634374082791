import React, { Component } from 'react';
import './marinePartnerships-Body.css';

export default class MarinePartnershipsBody extends Component {
  render() {
    return(
      <div id="autoResearch-womenInAuto-container">
        <div class="autoResearch-womenInAuto-content-container">
          {/* <div class="interestArea-domain-title">
            <h2>We Are Puget Sound - Discovering & Recovering the Salish Sea</h2>
          </div> */}
          <br/>
          <br/>
          <div class="autoResearch-womenInAuto-paragraph">
            <p>Community partners play a key role in what we do. From collaborating with non-profits to expand their ability to provide meaningful ocean learning experiences, to co-designing programs with public school teachers, the Marine Science Team believes that the best opportunities are the ones developed alongside existing leaders in the field who are already working to deliver value to youth. We partner with schools, aquariums, non-profits, and informal learning spaces to share resources and expand our collective reach.</p>
          </div>
          <div class="interestArea-domain-title">
            <h2>Please click some of the links below to learn more about the great work our partners are doing:</h2>
          </div>
          <div class="autoResearch-womenInAuto-paragraph">
            <p>•  <a href="https://www.pacificmarineresearch.org/" target="_blank" rel="noopener noreferrer">Pacific Marine Research</a></p>
            <p>•  <a href="https://salishseasciences.org/" target="_blank" rel="noopener noreferrer">Salish Sea Sciences</a></p>
            <p>•  <a href="https://envsciencecenter.org/" target="_blank" rel="noopener noreferrer">Environmental Science Center</a></p>
            <p>•  <a href="https://aspiringyouth.net/" target="_blank" rel="noopener noreferrer">Aspiring Youth</a></p>
            <p>•  <a href="https://survivethesound.org/home" target="_blank" rel="noopener noreferrer">Survive the Sound</a></p>
            <p>•  <a href="https://www.everettcc.edu/programs/stem-health-prof/orca" target="_blank" rel="noopener noreferrer">Everett Community College - Ocean Research College Academy</a></p>
            <p>•  <a href="https://www.zoo.org/" target="_blank" rel="noopener noreferrer">Woodland Park Zoo</a></p>
            <p>•  <a href="https://basilicabio.org/" target="_blank" rel="noopener noreferrer">Basilica Bio</a></p>
            <p>•  <a href="https://uwrov.org/" target="_blank" rel="noopener noreferrer">UWROV</a></p>
          </div>
          {/* <a href="https://www.wearepugetsound.org/" target="_blank" rel="noopener noreferrer">    
            <div class="autoResearch-cards-container">
              <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Resources/We+Are+Puget+Sound.png" alt="we are puget sound"/>
              <p class="autoResearch-cards-title">We Are Puget Sound</p>
              <div class="autoResearch-cards-overlay"></div>
            </div>  
          </a>
          <br/>
          <br/>
          <a href="https://www.wearepugetsound.org/" target="_blank" rel="noopener noreferrer">    
            <div class="autoResearch-cards-container">
              <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Resources/We+Are+Puget+Sound.png" alt="we are puget sound"/>
              <p class="autoResearch-cards-title">We Are Puget Sound</p>
              <div class="autoResearch-cards-overlay"></div>
            </div>  
          </a> */}
        </div>
      </div>
    );
  }
}