import React, { Component } from 'react';

export default class CarpentryLandingPageLinks extends Component {
  render() {
    return(
      <div id="projectsLandingPage-links-container">
        <div className="projectsLandingPage-links-content-container">
          <div className="interestArea-cardsList2-container">
            {/* <div className="interestArea-domain-title">
              <h2>Projects</h2>
            </div> */}
            <ul id="interestArea-cardsList2" className="interestArea-events-list2">
              <li>
                <a href="https://medium.com/the-foundry10-voice/youth-learn-carpentry-and-build-community-at-sawhorse-revolution-422f03332b89" target="_blank" rel="noopener noreferrer">
                  <div className="student-work-card-container">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/CTE/Carpentry/Sawhorse-Revolution.png" alt="youth skateboarder jumping off a wooden ramp" />
                    <p id="Everyday Unseen" className="student-work-card-title">Sawhorse Revolution Partnership: Wunderbar Tiny House Project</p>
                    <div id="Everyday Unseen" className="student-work-card-overlay"></div>
                 </div>
                </a>
              </li>
              <li>
                <a href="https://medium.com/the-foundry10-voice/seattle-skate-features-kickflips-into-the-sunset-42ecc73bea86" target="_blank" rel="noopener noreferrer">
                  <div className="student-work-card-container">
                    <img src="https://f10-programs.s3.us-west-2.amazonaws.com/trades/Carpentry/Seattle+Skate+Features+Interview.png" alt="youth skateboarder jumping off a wooden ramp" />
                    <p id="Everyday Unseen" className="student-work-card-title">Seattle Skate Features Interview</p>
                    <div id="Everyday Unseen" className="student-work-card-overlay"></div>
                 </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}