import React, { Component } from 'react';
// import CarpentryLandingPageIntro from './carpentryLandingPage-Intro';
import CarpentryLandingPageDescription from './carpentryLandingPage-Description';
import CarpentryLandingPageLinks from './carpentryLandingPage-Links';
import NewFooter from '../../../pages-other/NewFooter';
import './carpentryLandingPage-Content.css';

export default class CarpentryLandingPageContent extends Component {
  render() {
    return(
      <div id="carpentryLandingPage-content-container">
        {/* <CarpentryLandingPageIntro /> */}
        <CarpentryLandingPageDescription />
        <CarpentryLandingPageLinks />
        <NewFooter />
      </div>
    );
  }
}