import React, { Component } from 'react';
import './VR-Platforms.css';

export default class VrPlatforms extends Component {
  constructor(props) {
    super(props)
    this.state = {
      platform: []
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      platform: nextProps.all
    })
  }

  render() {
    if(this.state.platform) {
      const platformCards = this.state.platform.map((records, index) => (
        // <h4 key={index}>{records.name}</h4>
      <li>
        <div class="platform-content-container">
          <div class="platform-image-container">
            <img src={records.imageUrl} alt={records.name} />
          </div>
          <div class="platform-text-container">
            <p>{records.name}</p>
          </div>
        </div>
      </li>
      ));
      return(
      <div id="vrPlatform-container">
        <div class="vrPlatform-content-container">
          <h2>Available on:</h2>
          <ul class="vrPlatform-list">
            {platformCards}
          </ul>
        </div>
      </div>
    );
     } else {
      return (
        <p className='VisibilityOff'>

        </p>
      )
    }
  }
}