// Note: Use category contentCont: to add a a second paragraph to content section


const BenefitsData = [
    {
        benefit: {
            title: 'Short Work Weeks, Every Week',
            subtitle: 'Work a 32-hour week, Monday through Thursday.',
            img: 'https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Careers+Page/Benefits+Accordion/F10_Icons-01.png',
            alt: 'figure holding pencil next to checklist',
            content: 'We believe that all team members should have a healthy balance between their professional and personal lives. foundry10 first piloted a four-day work week during the COVID-19 pandemic, and this practice has now become a permanent part of our culture. We encourage team members to avoid sending emails or other work messages Friday-Sunday.'
        }
    },
    {
        benefit: {
            title: '$1,500 Annual Funds Available for Learning and Development Expenses',
            subtitle: "Advance your educational and professional growth without shrinking your bank account.",
            img: 'https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Careers+Page/Benefits+Accordion/F10_Icons-02.png',
            alt: 'figure working at desk with graduation cap in thought bubble',
            content: 'We proudly help team members invest in their professional development and continuing education. Each year, all full-time staff receive a $1,500 allowance to support costs related to learning and development. Employees are free to request support for a broad range of opportunities, including courses, conferences, certificate programs, and training materials.'
        }
    },
    {
        benefit: {
            title: '$2,000 Annual Funds Available for Well-Being and Hobby Expenses',
            subtitle: "Practice self-care and follow your passions. We’ll help cover your costs (seriously).",
            img: 'https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Careers+Page/Benefits+Accordion/F10_Icons-03.png',
            alt: 'figure skateboarding',
            content: 'We want our team members to flourish personally, as well as professionally. foundry10 provides all full-time employees with a $2,000 annual allowance to pay for activities that contribute to their personal interests and self-care. Employees can use this allowance to cover costs related to whatever activities support their personal well-being — from gym memberships to photography equipment.'
        }
    },
    {
        benefit: {
            title: 'Three Months of Full-Paid Parental Leave',
            subtitle: 'Take up to 12 weeks of paid leave to welcome your newest family member.',
            img: 'https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Careers+Page/Benefits+Accordion/F10_Icons-04.png',
            alt: 'figure interacting with baby stroller',
            content: 'We strive to always do what is best for youth and for our team members. To that end, foundry10 offers 12 weeks of paid leave for employees who are pregnant or recently added a new child to their family, whether through birth, adoption, or fostering.'
        }
    },
    {
        benefit: {
            title: 'Flexible Schedule',
            subtitle: `Base your work schedule on what works best for your needs.`,
            img: 'https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Careers+Page/Benefits+Accordion/F10_Icons-05+Round.png',
            alt: 'figure sitting within a clock holding minute hand',
            content: `Flexibility is central to foundry10’s work processes and culture, including our approach to individual schedules. Coordinating with their managers, employees have the freedom to adjust their availability on any given day to accommodate appointments, child care responsibilities, or other demands outside of work.`,
        }
    },
    {
        benefit: {
            title: '100% Dependent Coverage for Medical and Dental Benefits',
            subtitle: 'Ensure every member of your family gets the care they need.',
            img: 'https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Careers+Page/Benefits+Accordion/F10_Icons-07.png',
            alt: 'figure working on laptop with medical symbols surrounding',
            content: 'foundry10 is committed to holistically supporting the well-being of our staff, which includes completely covering dependent medical and dental premiums for full-time staff members. Current employees are also able to make pre-tax contributions to flexible spending accounts (FSAs), which they can use to cover dependent care costs in addition to their personal health related expenses.'
        }
    }
]

export default BenefitsData;