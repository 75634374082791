import React, { Component } from 'react';
import MarinePartnershipsIntro from './marinePartnerships-Intro';
import MarinePartnershipsBody from './marinePartnerships-Body';
import NewFooter from '../../../pages-other/NewFooter';
import './marinePartnerships-Content.css';

export default class MarinePartnershipsContent extends Component {
  render() {
    return(
      <div id="marinePartnerships-content-container">
        <MarinePartnershipsIntro />
        <MarinePartnershipsBody />
        <NewFooter />
      </div>
    );
  }
}