import React, { Component } from 'react';
import BeyondBoundariesIntro from './beyondBoundaries-Intro';
import BeyondBoundariesBody from './beyondBoundaries-Body';
import NewFooter from '../../../../pages-other/NewFooter';
import './beyondBoundaries-Content.css';

export default class BeyondBoundariesContent extends Component {
  render() {
    return(
      <div id="beyondBoundaries-content-container">
        <BeyondBoundariesIntro />
        <BeyondBoundariesBody />
        <NewFooter />
      </div>
    );
  }
}