import React, { Component } from 'react';
import ClubSupportFormHeader from './clubSupportForm-Header';
import ClubSupportFormContent from './clubSupportForm-Content';
import './clubSupportForm.css';

export default class ClubSupportForm extends Component {
  render() {
    return(
      <div id="clubSupportForm-container">
        <ClubSupportFormHeader />
        <ClubSupportFormContent />
      </div>
    );
  }
}