import React, { Component } from 'react';
import BeyondBoundariesHighlightsVideo from './beyondBoundaries-HighlightsVideo';
import BeyondBoundariesDocumentary from './beyondBoundaries-Documentary';
// import BeyondBoundariesPerformances from './beyondBoundaries-Performances';
import './beyondBoundaries-Body.css';

export default class BeyondBoundariesBody extends Component {
  render() {
    return(
      <div id="beyondBoundaries-container">
        <div class="beyondBoundaries-content-container">
          <div class="beyondBoundaries-logo-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Programs+%26+Projects/beyond+boundaries/Beyond+Boundaries+8+Logo.png" alt="beyond boundaries 8 logo"></img>
          </div>
          <div class="beyondBoundaries-title">
            <h2>Thanks for Joining Us at Beyond Boundaries 8!</h2>
            <div class="beyondBoundaries-title-line"></div>
          </div>
          {/* <div class="beyondBoundaries-information">
            <div class="beyondBoundaries-information-item">
              <h2>WHERE</h2>
              <p>Silas High School</p>
              <p>1202 N Orchard St</p>
              <p>Tacoma, WA 98406</p>
            </div>
            <div class="beyondBoundaries-information-item">
              <h2>WHEN</h2>
              <p>May 20, 2023</p>
              <p>7-9 p.m</p>
              <p>(doors open at 6:30 p.m.)</p>
            </div>
            <div class="beyondBoundaries-information-item">
              <h2>COST</h2>
              <p>This event is FREE to attend!</p>
              <p>No RSVP required.</p>
            </div>
          </div> */}

          <div class="beyondBoundaries-paragraph">
            <p>Thank you to everyone who joined us at Silas High School on May 20, 2023 for Beyond Boundaries 8. At this year’s event, high school dance teams from across the Seattle metro area showcased their original routines, got feedback from professional choreographers in the Seattle dance scene, and connected with other members of the local dance community.</p>
            <p>Participating dancers included:
              <ul>
                <li>
                  <p> 
                    <span className="beyondBoundaries-text-bold">High school teams: </span>  
                    <a href="https://www.youtube.com/watch?v=8TqL-DxDfec&list=PLxtu9__AnmvnJDWr50wFg_wI6Vyr5JTZw&index=6" target="_blank" rel="noopener noreferrer"> Todd Beamer</a>, 
                    <a href="https://www.youtube.com/watch?v=hnMcJJXq_So" target="_blank" rel="noopener noreferrer"> Shorecrest</a>, 
                    <a href="https://www.youtube.com/watch?v=9kLEQlDgZ9o" target="_blank" rel="noopener noreferrer"> Lincoln</a>, 
                    <a href="https://www.youtube.com/watch?v=6i-mECdW24o" target="_blank" rel="noopener noreferrer"> Lynnwood Royal Impact Dance Team</a>, 
                    <a href="https://www.youtube.com/watch?v=YGTGSH00oNU" target="_blank" rel="noopener noreferrer"> Sehome</a>, 
                    <a href="https://www.youtube.com/watch?v=GPLyEOMAnyA" target="_blank" rel="noopener noreferrer"> Bellingham</a>,  
                    <a href="https://www.youtube.com/watch?v=y_babj86nDA&list=PLxtu9__AnmvnJDWr50wFg_wI6Vyr5JTZw&index=29" target="_blank" rel="noopener noreferrer"> Sammamish</a>, and 
                    <a href="https://shs.sunnysideschools.org/" target="_blank" rel="noopener noreferrer"> Sunnyside</a>.

                  </p>
                </li>
                <li>
                  <p>
                  <span className="beyondBoundaries-text-bold">Exhibition teams: </span>  
                    The Garden, 
                    Backstage Foundation, 
                    <a href="https://www.youtube.com/user/wwuhiphop" target="_blank" rel="noopener noreferrer"> Western Washington University Dance Team</a>, and 
                    <a href="https://www.dailyuw.com/arts_and_culture/community/uw-undivided-winter-showcase-lights-up-the-hub/article_1d3ffbf2-bbdc-11ed-bcbf-8f712be2ea72.html" target="_blank" rel="noopener noreferrer"> Undivided (UW Dance Team)</a>.
                  </p>
                </li>
                <li>
                  <p>
                  <span className="beyondBoundaries-text-bold">Special guest choreographers: </span>  
                    <a href="https://www.instagram.com/theaverygardner/?hl=en" target="_blank" rel="noopener noreferrer">Avery Gardner</a>, 
                    <a href="https://www.instagram.com/kristengracedance/?hl=en" target="_blank" rel="noopener noreferrer"> Kristen Grace</a>, and 
                    <a href="https://www.seahawks.com/news/discipline-and-perfect-timing-is-everything-for-master-sergeant-and-seahawks-dan" target="_blank" rel="noopener noreferrer"> Vince John Frijas</a>.
                  </p>
                </li>
              </ul>
              <p>The theme for this year's performances was "Juxtaposition." Check out the video below to watch highlights from the 2023 show!</p>
            </p>
          </div>
          <BeyondBoundariesHighlightsVideo />
          {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/WtgKTcax_gY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}

          <div class="beyondBoundaries-title">
            <h2>About Beyond Boundaries</h2>
            <div class="beyondBoundaries-title-line"></div>
          </div>

          <div class="beyondBoundaries-paragraph">
            <p>Beyond Boundaries is an annual high school dance exhibition where we give you more than just a platform to dance on. foundry10 saw a gap in the high school arena of dance where students aren’t able to express their own voice, be exposed to a multitude of different styles, and learn how to bridge the gap from high school to the greater Washington dance scene. Beyond Boundaries brings all of these components into a one-day experience.</p>
            {/* <p>This year's event will feature dance teams from:</p>
            <p>
              <ul>
                <li>Sehome High School</li>
                <li>Lincoln High School</li>
                <li>Shorecrest High School</li>
                <li>Lynnwood High School</li>
                <li>Gig Harbor High School</li>
              </ul>
            </p>
            <p>Stay tuned to this page for additional information about this year’s event. Attendance is free and open to all. No RSVP is required.</p> */}
          </div>
          <div class="beyondBoundaries-title">
            <h2>Check Out This Documentary to Learn More</h2>
            <div class="beyondBoundaries-title-line"></div>
          </div>
          <BeyondBoundariesDocumentary />
          <div class="beyondBoundaries-title">
            <h2>Watch Performances from Previous Years</h2>
            <div class="beyondBoundaries-title-line"></div>
          </div>
          {/* <BeyondBoundariesPerformances /> */}
          <div className="beyondBoundaries-paragraph">
              <p><span className="beyondBoundaries-text-bold">2019</span>
                <ul>
                  <li>
                      <a href="https://youtu.be/t1gPKpOOLUU" target="_blank" rel="noopener noreferrer">Bellingham High School</a>
                  </li>
                  <li>
                      <a href="https://youtu.be/ybrYFeu63RE" target="_blank" rel="noopener noreferrer">Bishop Blanchet High School </a>
                  </li>
                  <li>
                      <a href="https://youtu.be/_7vXFb6fpK8" target="_blank" rel="noopener noreferrer">Curtis High School</a>
                  </li>
                  <li>
                      <a href="https://youtu.be/n2w67l7HiNY" target="_blank" rel="noopener noreferrer">Federal Way High School</a>
                  </li>
                  <li>
                      <a href="https://youtu.be/rTqqFCJGcY0" target="_blank" rel="noopener noreferrer">Lynnwood High School</a>
                  </li>
                  <li>
                      <a href="https://youtu.be/Vp69onUE6-E" target="_blank" rel="noopener noreferrer">Sammamish High School</a>
                  </li>
                  <li>
                      <a href="https://youtu.be/vRECqBXx7Lc" target="_blank" rel="noopener noreferrer">Sehome High School</a>
                  </li>
                  <li>
                      <a href="https://youtu.be/KLhwIcwjHew" target="_blank" rel="noopener noreferrer">Shorewood High School</a>
                  </li>
                  <li>
                      <a href="https://youtu.be/ptPjVKtO2lM" target="_blank" rel="noopener noreferrer">Tacoma SOTA</a>
                  </li>
                </ul>
              </p>
              <p><span className="beyondBoundaries-text-bold">2018</span>
                <ul>
                  <li>
                      <a href="https://youtu.be/CNAmjRxRETI" target="_blank" rel="noopener noreferrer">Opening Video</a>
                  </li>
                  <li>
                      <a href="https://youtu.be/47kXs2dr81s" target="_blank" rel="noopener noreferrer">Bishop Blanchet High School</a>
                  </li>
                  <li>
                      <a href="https://youtu.be/3tIwVYZQNyg" target="_blank" rel="noopener noreferrer">Federal Way High School</a>
                  </li>
                  <li>
                      <a href="https://youtu.be/XNeFdjZd7Xk" target="_blank" rel="noopener noreferrer">Sammamish High School</a>
                  </li>
                  <li>
                      <a href="https://youtu.be/PHKHsTKbJ5w" target="_blank" rel="noopener noreferrer">Sehome High School</a>
                  </li>
                  <li>
                      <a href="https://youtu.be/qbyUWYa6AOg" target="_blank" rel="noopener noreferrer">Shorecrest High School</a>
                  </li>
                  <li>
                      <a href="https://youtu.be/_3yWaneXzWg" target="_blank" rel="noopener noreferrer">Shorewood High School</a>
                  </li>
                  <li>
                      <a href="https://youtu.be/n9SKmIfMqEA" target="_blank" rel="noopener noreferrer">Todd Beamer High School</a>
                  </li>
                </ul>
              </p>
          </div>
        </div>
      </div>
    );
  }
}