import React from 'react';

export const LegacySubjectsData = [
      {
        title: 'Artistic Design',
        path: '/programs/artistic-design',
        icon: <i className="fa-solid fa-palette"></i>
      },
      {
        title: 'Career and Technical Education',
        path: '/programs/career-and-technical-education',
        icon: <i className="fa-solid fa-briefcase"></i>
      },
      {
        title: 'Dance',
        path: '/programs/dance',
        icon: <i className="fa-solid fa-person-walking"></i>
      },
      {
        title: 'Digital Audio',
        path: '/programs/digital-audio',
        icon: <i className="fa-solid fa-headphones-simple"></i>
      },
      {
        title: 'Digital Civics',
        path: '/programs/digital-civics',
        icon: <i className="fa-solid fa-tachograph-digital"></i>
      },
      {
        title: 'Dramatic Arts',
        path: '/programs/dramatic-arts',
        icon: <i className="fa-solid fa-masks-theater"></i>
      },
      {
        title: 'EdTech',
        path: '/programs/edtech',
        icon: <i className="fa-solid fa-laptop-code"></i>
      },
      {
        title: 'Film',
        path: '/programs/film',
        icon: <i className="fa-solid fa-film"></i>
      },
      {
        title: 'Financial Education',
        path: '/programs/financial-education',
        icon: <i className="fa-solid fa-chart-line"></i>
      },
      {
        title: 'Games & Learning',
        path: '/programs/games-and-learning',
        icon: <i className="fa-solid fa-dice"></i>
      },
      {
        title: 'Health Education',
        path: '/programs/health-education',
        icon: <i className="fa-solid fa-laptop-medical"></i>
      },
      {
        title: 'Internships',
        path: '/programs/internships',
        icon: <i className="fa-solid fa-id-card-clip"></i>
      },
      {
        title: 'Interventions',
        path: '/programs/interventions',
        icon: <i className="fa-solid fa-hand-holding-heart"></i>
      },
      {
        title: 'Marine Science',
        path: '/programs/marine-science',
        icon: <i className="fa-solid fa-otter"></i>
      },
      {
        title: 'STEM',
        path: '/programs/stem',
        icon: <i className="fa-solid fa-flask"></i>
        }
];