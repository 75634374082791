import React, { Component } from 'react';
import VrStressTestHeader from './vrStressTest-Header';
import VrStressTestContent from './vrStressTest-Content';
import './vrStressTest.css';

export default class VrStressTest extends Component {
  render() {
    return(
      <div id="vrStressTest-container">
        <VrStressTestHeader />
        <VrStressTestContent />
      </div>
    );
  }
}