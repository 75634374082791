import React, { Component } from 'react';
import './adolescentsLandingPage-Links.css';

export default class adolescentsLandingPageLinks extends Component {
  render() {
    return(
      <div id="adolescentsLandingPage-links-container">
        <div class="adolescentsLandingPage-links-content-container">
          <div class="interestArea-cardsList2-container">
            <div class="interestArea-domain-title">
              <h2>Resources</h2>
            </div>
            <ul id="interestArea-cardsList2" class="interestArea-events-list2">
              <a href={'https://drive.google.com/file/d/1iUO5ClyEE51QFTu_Wak57_jMSKptpG_z/view?usp=sharing'} target="_blank">
               <li>
                <div class="adolescentsLandingPage-cards-container">
                  <img src="https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Programs+%26+Projects/Financial+Education/Adolescent+Financial+Ed+Survey.png" alt="infographic describing financial education experiences" />
                  <p class="adolescentsLandingPage-cards-title">Survey Results</p>
                  <div class="adolescentsLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
              <a href={'https://medium.com/the-foundry10-voice/youth-want-more-opportunities-for-financial-education-survey-48877063abbd'} target="_blank">
               <li>
                <div class="projectsLandingPage-cards-container">
                  <img src="https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Programs+%26+Projects/Financial+Education/Medium+Article+Screenshot.png" alt="screenshot of medium blog post titled Youth Want More Opportunities for Financial Education: Survey" />
                  <p class="projectsLandingPage-cards-title">Medium Article</p>
                  <div class="projectsLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}