import React, { Component } from 'react';
import './Work-Culture.css';

export default class WorkCulture extends Component {
  render() {
    return(
      <div id="work-culture-container">
        <div class="work-culture-content-container">
          <h3>Work Culture</h3>
          <p>At foundry10, our culture is designed to ensure that we can capture a diverse array of viewpoints to explore learning and create direct value for youth and children.  Our work culture is flexible by design and encourages a creative and collaborative approach driven by the expertise, curiosity, needs, and well-being of each person we serve and our staff.</p>
        </div>
      </div>
    );
  }
}