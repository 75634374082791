import React, { Component } from 'react';
// import CardData2 from './Cards-Data2';
// import FinalCards from './Final-Cards';
// import Footer from '../pages-events/Footer';
import NewFooter from '../pages-other/NewFooter';
import MissionStatement from './MissionStatement';
import StudentHighlights from '../pages-student-highlights/Student-Highlights';
import HighlightTitle from './Highlight-Title';
import EverydayTitle from './Everyday-Title';
import TraditionalArtHeader from '../pages-student-highlights/everyday-unseen/traditionalArt-header';
import TraditionalArtCards from '../pages-student-highlights/everyday-unseen/traditionalArt-cards';
import DigitalPrintHeader from '../pages-student-highlights/everyday-unseen/digitalPrint-header';
import DigitalPrintCards from '../pages-student-highlights/everyday-unseen/digitalPrint-cards';
import ThreeDMixedMediaHeader from '../pages-student-highlights/everyday-unseen/threeDMixedMedia-header';
import ThreeDMixedMediaCards from '../pages-student-highlights/everyday-unseen/threeDMixedMedia-cards';
import './Cards2.css';

class Cards2 extends Component {
  render() {
    return(
      <div id="card2-container">
        <MissionStatement />
        <HighlightTitle />
        <StudentHighlights />
        <EverydayTitle />
        <TraditionalArtHeader />
        <TraditionalArtCards />
        <DigitalPrintHeader />
        <DigitalPrintCards />
        <ThreeDMixedMediaHeader />
        <ThreeDMixedMediaCards />
        <NewFooter />
        {/* <FinalCards /> */}
        {/* <CardData2 /> */}
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Cards2;