import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import CarpentryLandingPageHeader from './carpentryLandingPage-Header';
import CarpentryLandingPageContent from './carpentryLandingPage-Content';
import './carpentryLandingPage.css';

export default class CarpentryLandingPage extends Component {
  render() {
    return(
      <div id="carpentryLandingPage-container">
        <Helmet>
          <title>foundry10 Career and Technical Education: Carpentry</title>
          <meta
            name="title"
            content="foundry10 Career and Technical Education: Carpentry"
          />
          <meta
            name="description"
            content="Explore the value of hands-on learning in carpentry and construction careers. See how students benefit from interacting with materials and tools."
          />
          <meta property="og:title" content="foundry10 Career and Technical Education: Carpentry" />
          <meta
            property="og:description"
            content="Explore the value of hands-on learning in carpentry and construction careers. See how students benefit from interacting with materials and tools."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/carpentry" />
          <link rel="canonical" href="https://www.foundry10.org/carpentry" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Career and Technical Education: Carpentry",
                "description": "Explore the value of hands-on learning in carpentry and construction careers. See how students benefit from interacting with materials and tools.",
                "url": "https://www.foundry10.org/carpentry"
              }
            `}
          </script>
        </Helmet>
        <CarpentryLandingPageHeader />
        <CarpentryLandingPageContent />
      </div>
    );
  }
}