import React, { Component } from 'react';
import './OurStory-Description.css';

export default class OurStoryDescipriton extends Component {
  render() {
    return(
      <div id="ourStory-description-container">
        <div class="ourStory-description-content-container">
          <p>We've been expanding ideas about learning and creating direct value for youth for over a decade. Read below to learn a bit about our unique approach and how we got started.</p>
        </div>
      </div>
    );
  }
}