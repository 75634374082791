import React, { Component } from 'react';
import './vrResources-Links.css';

export default class AutoLandingPageLinks extends Component {
  render() {
    return(
      <div id="projectsLandingPage-links-container">
        <div class="projectsLandingPage-links-content-container">
          <div class="interestArea-cardsList2-container">
            {/* <div class="interestArea-domain-title">
              <h2>Projects</h2>
            </div> */}
            <ul id="interestArea-cardsList2" class="interestArea-events-list2">
              <li>
                <a href="https://www.foundry10.org/research/vr-hardware-comparison-table">
                  <div class="vrResources-card-container">
                    <img src="https://f10-programs.s3.us-west-2.amazonaws.com/virtual-reality/VR+Resources/VR+Hardware+Comparison+Table+Card.jpg" alt="class of teenagers using virtual reality" />
                    <p id="Everyday Unseen" class="vrResources-card-title">VR Hardware Comparison Table</p>
                    <div id="Everyday Unseen" class="vrResources-card-overlay"></div>
                 </div>
                </a>
              </li>
              <li>
                <a href="https://www.foundry10.org/vr-game-review">
                  <div class="vrResources-card-container">
                    <img src="https://f10-programs.s3.us-west-2.amazonaws.com/virtual-reality/VR+Resources/VR+Game+Reviews+Card.jpeg" alt="young girl using virtual reality" />
                    <p id="Everyday Unseen" class="vrResources-card-title">Virtual Reality Game Reviews</p>
                    <div id="Everyday Unseen" class="vrResources-card-overlay"></div>
                 </div>
                </a>
              </li>
              <li>
                <a href="https://www.foundry10.org/vr-learning-map">
                  <div class="vrResources-card-container">
                    <img src="https://f10-programs.s3.us-west-2.amazonaws.com/virtual-reality/VR+Resources/VR+Learning+Map+Header+Card.jpg" alt="adult woman with young girl using virtual reality" />
                    <p id="Everyday Unseen" class="vrResources-card-title">VR Learning Map</p>
                    <div id="Everyday Unseen" class="vrResources-card-overlay"></div>
                 </div>
                </a>
              </li>
              <li>
                <a href="https://www.foundry10.org/research/50-tips-for-vr-in-the-classroom">
                  <div class="vrResources-card-container">
                    <img src="https://f10-programs.s3.us-west-2.amazonaws.com/virtual-reality/VR+Resources/50+Tips+for+VR+in+the+Classroom+Card.png" alt="illustration of two youth using virtual reality" />
                    <p id="Everyday Unseen" class="vrResources-card-title">50 Tips For VR in the Classroom</p>
                    <div id="Everyday Unseen" class="vrResources-card-overlay"></div>
                 </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}