import React, { Component } from 'react';
import './OurStory-IllustrationTwo.css';

export default class OurStoryIllustrationTwo extends Component {
  render() {
    return(
      <div id="ourStory-second-headerImage-container">
      <div class="ourStory-second-container">
        <div class="ourStory-second-header-image">
          <img src="https://f10-misc.s3-us-west-2.amazonaws.com/Our+Story+Illustration+Two.png" alt="lisa brainstorming" />
        </div>
      </div>
      <div class="ourStory-second-text-container">
        <div class="ourStory-second-realTitle-container">
          <h1>Approaching learning through a different lens</h1>
        </div>
        <div class="ourStory-second-title-container"/>
        <div class="text-box-one-second">
          <p>At the same time that Lisa was thinking about these issues, Gabe Newell, a parent at the school where Lisa taught, was considering similar things. After connecting through their shared appreciation of video games, Lisa and Gabe began to think about how an organization that approaches learning through a different lens might look. Though Gabe’s background is not in education, his own experiences with the field made him curious about why the system approaches learning the way it does. He also was intrigued by the idea of examining learning in more interesting ways, and to find those which might resonate better with students. Lisa’s background as an educator provided an opportunity to genuinely connect with schools and teachers. Together, they decided to team up and create foundry10, a philanthropic educational research organization focused on expanding the way people think about learning and creating direct value for kids.</p>
        </div>
        <div class="text-box-two-second">
          {/* <h2>"Here is a sample quote that someone said."</h2>
          <h3>-Tom Swanson</h3> */}
        </div>
      </div>
  </div>
    );
  }
}