import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
// 
import './StudentWork2.css';
// import Posts from '../pages-student-work/Posts';
// import SideBar3 from '../pages-student-work/SideBar3';
import StudentWorkHeader from './Header2';
import Cards2 from './Cards2';

class StudentWork2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subjectAreas: [],
            subjectAreaNames:[],
            years: [],
            sort_by: '',
            posts: [],
            filtered_posts: [],
            filtered_posts_left: [],
            filtered_posts_right: [],
            search:''
        }
        this.top = React.createRef();
        this.changeState = this.changeState.bind(this);
        this.filterPosts = this.filterPosts.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.getNames = this.getNames.bind(this);
    }

    componentDidMount() {
        //get all subject areas and all student work
        axios.get('/studentwork/getAllStudentWork')
            .then(res => {
                // console.log('res.data: ', res.data);
                //automatically sort by date-posted
                res.data.sort((a, b) => {
                    var dateA = a.date_posted;
                    var dateB = b.date_posted;
                    if (dateA < dateB) {
                        return -1;
                    }
                    if (dateA > dateB) {
                        return 1;
                    }
                    return 0;
                });
                this.filterPosts(res.data);
                axios.get('/programs/getSubjectAreas')
                    .then(subjects => {
                        console.log('subjects.data: ', subjects.data);
                        this.getNames(subjects.data);
                        this.setState({
                            subjectAreas: subjects.data,
                            posts: res.data,
                            filtered_posts: res.data
                        })
                    })
                    .catch(err => {
                        console.log('err: ', err);
                    })
            })
    }

    getNames(data){
        for(var i in data){
            this.state.subjectAreaNames.push(data[i].name);
        }
    }

    changeState(data) {
        console.log('filtered posts in StudentWork: ', data.posts);
        /* for(var i in data.filtered_posts){
            this.state.filtered_posts.push(data.filtered_posts[i]);
        } */
        console.log('change State', this.state);
        this.filterPosts(data.posts);
        this.setState({
            sort_by: data.sort_key,
            search: data.search_key,
        });
    }

    filterPosts(posts) {
        var temp_left = [];
        var temp_right = [];
        for (var i in posts) {
            if (i % 2 === 0) {
                //console.log('even');
                temp_left.push(posts[i]);
            } else {
                //console.log('odd');
                temp_right.push(posts[i]);
            }
        }
        this.setState({
            filtered_posts_left: temp_left,
            filtered_posts_right: temp_right
        })
    }

    handleSearch(event){
        event.preventDefault();
        var filtered_post_array = [];
        if(this.state.search === ''){
            filtered_post_array = this.state.posts;
        } else {
            var filteredPostsSet = new Set();
            var searchArray = this.state.search.trim().toLowerCase().split(' '); //array of lowercase strings
            console.log('searchArray: ', searchArray); 
            var posts = this.state.posts; //array of objects
            var names = this.state.subjectAreas; //array of strings
            for(var i in posts) {
                for (var j in searchArray) { 
                    //check if search word is a subject area name 
                    for (var k in names) {
                        if(names[k].name.toLowerCase().search(searchArray[j]) !== -1){ //word found
                            if(posts[i].subjectAreas.includes(names[k]._id)){
                                filteredPostsSet.add(posts[i]);
                            }
                        }
                    }
                    if(posts[i].name.toLowerCase().search(searchArray[j]) !== -1
                        || posts[i].student.toLowerCase().search(searchArray[j]) !== -1
                        || posts[i].description.toLowerCase().search(searchArray[j]) !== -1
                        || posts[i].year.search(searchArray[j]) !== -1){
                        filteredPostsSet.add(posts[i]);
                    }
                }
            }
            for(var x of filteredPostsSet){
                filtered_post_array.push(x);
            }
        }
        this.setState({
            filtered_posts: filtered_post_array
        })
    }

    handleChange(event){
        this.setState({
            [event.target.name]:event.target.value
        })
    }

    render() {
        return (
            <div id="studentWork-main-component">
                <Helmet>
          <title>foundry10 Student Work Showcase</title>
          <meta
            name="title"
            content="foundry10 Student Work Showcase"
          />
          <meta
            name="description"
            content="Explore the creations of youth in our student work showcase. From games to choreographies to films, see what students can achieve with the right tools and support."
          />
          <meta property="og:title" content="foundry10 Student Work Showcase" />
          <meta
            property="og:description"
            content="Explore the creations of youth in our student work showcase. From games to choreographies to films, see what students can achieve with the right tools and support."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/student-work" />
          <link rel="canonical" href="https://www.foundry10.org/student-work" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Student Work Showcase",
                "description": "Explore the creations of youth in our student work showcase. From games to choreographies to films, see what students can achieve with the right tools and support.",
                "url": "https://www.foundry10.org/student-work"
              }
            `}
          </script>
        </Helmet>
                <StudentWorkHeader />
                <Cards2 />
            </div>
        );
    }
}

export default StudentWork2;