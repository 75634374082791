import React, { Component } from 'react';
import './careerConnectedLearningLifeSkills-Team.css';

export default class CareerConnectedLearningLifeSkillsTeam extends Component {
  render() {
    return (
      <div className="careerConnectedLearningLifeSkillsTeam-container">
        <div className="careerConnectedLearningLifeSkillsTeam-content-container">
          <h2 className="careerConnectedLearningLifeSkillsTeam-title">Meet the Team Leading This Work</h2>
          {/* <h2>Current Lab Members</h2> */}
          {/* <h2 className="careerConnectedLearningLifeSkillsEducationTeam-titleContent-container"><span>Meet the Team Leading This Work</span></h2> */}
          <div class="careerConnectedLearningLifeSkillsTeam-paragraph">
            {/* <h2>To put it simply, conceptual development means 'learning'</h2> */}
            <p>As career pathways and in-demand skills in the 21st-century workforce continue to rapidly evolve, it is necessary that today’s youth can access high-quality, relevant education opportunities that will prepare them for lifelong success.</p>
            <p>Through programming and philanthropic support, our team helps young people pursue a rewarding livelihood by supporting career exploration, work and training experiences, and life skills development. We also conduct and share findings from research on opportunities that advance career-connected learning experiences for youth.</p>
          </div>
          <ul className="careerConnectedLearningLifeSkillsTeam-cardsList">
            <li>
              <a href="/about/team/handa-yoh" className="careerConnectedLearningLifeSkillsTeam-linkNew">
                <div className="careerConnectedLearningLifeSkillsTeam-cardNew">
                  <div className="careerConnectedLearningLifeSkillsTeam-card-imageNew">
                    <img src='https://s3.us-west-2.amazonaws.com/craft-images/Team/Headshots/Team-Headshot-Template-8.png' width="100%" alt="young woman in black sweater smiling"/>
                  </div>
                  <div className="careerConnectedLearningLifeSkillsTeam-card-contentNew">   
                    <h2>Handa Yoh</h2>
                    <p>Team Lead</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/callie-bishop" className="careerConnectedLearningLifeSkillsTeam-linkNew">
                <div className="careerConnectedLearningLifeSkillsTeam-cardNew">
                  <div className="careerConnectedLearningLifeSkillsTeam-card-imageNew">
                    <img src='https://s3-us-west-2.amazonaws.com/f10-people/Re-edited+Team+Photos+230x300/Callie.jpg' width="100%" alt="young woman in grey shirt smiling"/>
                  </div>
                  <div className="careerConnectedLearningLifeSkillsTeam-card-contentNew">   
                    <h2>Callie Bishop</h2>
                    <p>Executive Assistant</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/cat-sanchez" className="careerConnectedLearningLifeSkillsTeam-linkNew">
                <div className="careerConnectedLearningLifeSkillsTeam-cardNew">
                  <div className="careerConnectedLearningLifeSkillsTeam-card-imageNew">
                    <img src='https://craft-images.s3.us-west-2.amazonaws.com/Team/Headshots/Staff-Headshot-Cat-Sanchez-2024.png' width="100%" alt="woman in black sweater smiling"/>
                  </div>
                  <div className="careerConnectedLearningLifeSkillsTeam-card-contentNew">   
                    <h2>Cat Sanchez</h2>
                    <p>Associate Researcher</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/linh-phu-maki" className="careerConnectedLearningLifeSkillsTeam-linkNew">
                <div className="careerConnectedLearningLifeSkillsTeam-cardNew">
                  <div className="careerConnectedLearningLifeSkillsTeam-card-imageNew">
                    <img src='https://s3.us-west-2.amazonaws.com/craft-images/Team/Headshots/Staff-Headshot_Linh-Phu-Maki.jpg' width="100%" alt="woman in gray blouse"/>
                  </div>
                  <div className="careerConnectedLearningLifeSkillsTeam-card-contentNew">   
                    <h2>Linh Phu Maki</h2>
                    <p>Senior HR Generalist</p>
                  </div>
                </div>
              </a>
            </li>

          </ul>
        </div>
      </div>
    );
  }
}