import React, { Component } from 'react';
import './vrLandingPage-Links.css';

export default class VrLandingPageLinks extends Component {
  render() {
    return(
      <div id="vrLandingPage-links-container">
        <div class="vrLandingPage-links-content-container">
          <div class="interestArea-cardsList2-container">
            <div class="interestArea-domain-title">
              <h2>Review Content</h2>
            </div>
            <ul id="interestArea-cardsList2" class="interestArea-events-list2">
              <a href={'/vr-game-review/arts'}>
               <li>
                <div class="vrLandingPage-cards-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/virtual-reality/VR+Blog+Photos/Arts+Small.jpg" alt="fun" />
                  <p class="vrLandingPage-cards-title">Arts</p>
                  <div class="vrLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
              <a href={'/vr-game-review/empathy'}>
               <li>
                <div class="vrLandingPage-cards-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/virtual-reality/VR+Blog+Photos/Empathy+Small.jpg" alt="fun" />
                  <p class="vrLandingPage-cards-title">Empathy</p>
                  <div class="vrLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
              <a href={'/vr-game-review/fun'}>
               <li>
                <div class="vrLandingPage-cards-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/virtual-reality/VR+Blog+Photos/Fun+Small.jpg" alt="fun" />
                  <p class="vrLandingPage-cards-title">Fun</p>
                  <div class="vrLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
              <a href={'/vr-game-review/history'}>
               <li>
                <div class="vrLandingPage-cards-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/virtual-reality/VR+Blog+Photos/History+Small.jpg" alt="fun" />
                  <p class="vrLandingPage-cards-title">History</p>
                  <div class="vrLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
              <a href={'/vr-game-review/math'}>
               <li>
                <div class="vrLandingPage-cards-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/virtual-reality/VR+Blog+Photos/Math+Small.jpg" alt="fun" />
                  <p class="vrLandingPage-cards-title">Math & Engineering</p>
                  <div class="vrLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
              <a href={'/vr-game-review/science'}>
               <li>
                <div class="vrLandingPage-cards-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/virtual-reality/VR+Blog+Photos/Science+Small.jpg" alt="fun" />
                  <p class="vrLandingPage-cards-title">Science</p>
                  <div class="vrLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
              <a href={'/vr-game-review/visualization'}>
               <li>
                <div class="vrLandingPage-cards-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/virtual-reality/VR+Blog+Photos/Visualization+Small.png" alt="fun" />
                  <p class="vrLandingPage-cards-title">Visualization</p>
                  <div class="vrLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}