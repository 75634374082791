import React, { Component } from 'react';
import SmartHeader from './smart-Header';
import SmartContent from './smart-Content';
import './smart.css';

export default class Smart extends Component {
  render() {
    return(
      <div id="smart-container">
        <SmartHeader />
        <SmartContent />
      </div>
    );
  }
}