import React, { Component } from 'react';
import OurStoryDescription from './OurStory-Description';
import OurStoryIllustrationOne from './OurStory-IllustrationOne';
import OurStoryIllustrationTwo from './OurStory-IllustrationTwo';
import OurStoryIllustrationThree from './OurStory-IllustrationThree';
import NewFooter from '../../../pages-other/NewFooter';
import './OurStory-Content.css';

export default class OurStoryContent extends Component {
  render() {
    return(
      <div id="ourStory-content-container">
        <OurStoryDescription />
        <OurStoryIllustrationOne />
        <OurStoryIllustrationTwo />
        <OurStoryIllustrationThree />
        <NewFooter />
      </div>
    );
  }
}