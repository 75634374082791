import React, { Component } from 'react';
import './carpentryLandingPage-Header.css';

export default class CarpentryLandingPageHeader extends Component {
  render() {
    return(
      <div id="carpentryLandingPage-header-container">
          <div className="carpentryLandingPage-header-content-container">
            <h1>Carpentry</h1>
          </div>
      </div>
    );
  }
}