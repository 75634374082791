import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import NewFooter from '../pages-other/NewFooter';
import './funReviews.css';

export default class FunReviews extends Component {
  constructor(props) {
    super(props)
    this.state = {
      funReviews: []
    };
  }

  componentDidMount() {
    axios.get('/virtualteachers/getFunReviews')
      .then(data => {
        this.setState({
          funReviews: data.data
        })
      }).catch(err => {
        console.log('err in /getFunReviews: ', err);
      })
  }

  render() {
    if(this.state.funReviews) {
      const funReviewCards = this.state.funReviews.map((records, index) => (
        <a href={'/vr-game-review/'+records.category+'/'+records.nameShort} class="vrReviewList-link">
          <li>
            <div class="vrReviewList-card-container">
              <div class="vrReviewList-card-content-container">
                <div class="vrReviewList-card-image-container">
                  <img src={records.imageUrl} alt={records.name}/>
                </div>
                <div class="vrReviewList-card-text-container">
                  <div class="vrReviewList-card-mainText-art">
                    <h2>{records.name}</h2>
                    <div class="vrReviewList-card-description-container">
                      <h4>Reviewed by {records.reviewedBy},</h4>
                      <h4>{records.reviewDate}</h4>
                    </div>
                    <p>{records.summary}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </a>
      ));
        return(
          <div id="reviewList-main-container">
            <Helmet>
          <title>foundry10 Virtual Reality Game Reviews: Fun</title>
          <meta
            name="title"
            content="foundry10 Virtual Reality Game Reviews: Fun"
          />
          <meta
            name="description"
            content="Explore VR games curated by foundry10 for classroom Fun. Includes both student expression and arts made for VR."
          />
          <meta property="og:title" content="foundry10 Virtual Reality Game Reviews: Fun" />
          <meta
            property="og:description"
            content="Explore VR games curated by foundry10 for classroom Fun. Includes both student expression and arts made for VR."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/vr-game-review/fun" />
          <link rel="canonical" href="https://www.foundry10.org/vr-game-review/fun" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Virtual Reality Game Reviews: Fun",
                "description": "Explore VR games curated by foundry10 for classroom Fun. Includes both student expression and arts made for VR.",
                "url": "https://www.foundry10.org/vr-game-review/fun"
              }
            `}
          </script>
        </Helmet>
          {/* HEADER */}
          <div id="reviewList-header-container-fun">
            <div class="reviewList-header-content-container">
              <h1>Fun</h1>
              <h4>Encourages student play</h4>
            </div>
          </div>
          {/* BODY */}
          <div id="reviewList-main-content-container">
            {/* DESCRIPTION */}
            {/* <div id="researchAll-description-container">
              <div class="researchAll-description-content-container">
                <h3>Explore our research by interest areas</h3>
                <p>All of our programs, projects and experiments are designed with the goal of learning something that will be of value to kids, educators or the community at large. Our research ranges from qualitative ethnographic case studies to quantitative experimental designs.</p>
              </div>
            </div> */}
            {/* LIST */}
            <div id="vrReviewList-container">
                <div class="vrReviewList-content-container">
                  <div class="vrReviewList-title-art">
                    <h2>VR Game Reviews</h2>
                  </div>
                  <ul id="vrReviewList-cardsList2">
                    {funReviewCards}
                  </ul>
                </div>
              </div>
            <NewFooter />
          </div>
        </div>     
        );
     } else {
      return (
        <p className='VisibilityOff'>

        </p>
      )
    }
  }
}