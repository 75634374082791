import React, { Component } from 'react';
import './culinary-Description.css';

export default class CulinaryDescription extends Component {
  render() {
    return(
      <div id="culinary-description-container">
        <div class="culinary-description-content-container">
          <p>Culinary Arts CTE programs offer a wide array of opportunities for students to explore food preparation, specialization areas, management, business skills, and industry-related training. We are interested in finding ways to support teachers and students who are pursuing these educational pathways, particularly during the COVID-19 pandemic. Without access to school equipment and training spaces, students will be required to do more learning at home. We are interested in both supporting and learning more about how remote culinary learning can work in a hands-on, functional way for students. This is a new area for us and we are excited to get some projects started!</p>
          {/* <p>Check back for more information coming soon.</p> */}
        </div>
      </div>
    );
  }
}