import React, { Component } from 'react';
import './marinePartnerships-Header.css';

export default class MarinePartnershipsHeader extends Component {
  render() {
    return(
      <div id="marinePartnerships-header-container">
          <div class="marinePartnerships-header-content-container">
            <h1>Marine Science Partnerships</h1>
          </div>
      </div>
    );
  }
}