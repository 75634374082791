import React, { Component } from 'react';
import './marinePartnerships-Intro.css';

export default class MarinePartnershipsIntro extends Component {
  render() {
    return(
      <div id="marinePartnerships-intro-container">
        <div class="marinePartnerships-intro-content-container">
          <div class="marinePartnerships-intro-bold">
            <h3>Explore how the Marine Science Team engages with community partners.</h3>
          </div>
          {/* <div class="marineEndlessBlue-intro-normal">
            <p>We aim to invest in marine science programs, research, and philanthropy that empower youth to consider their impact on the world and take environmental action in their community.</p>
          </div> */}
        </div>
      </div>
    );
  }
}