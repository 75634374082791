import React, { Component } from 'react';
import BandLabHeader from './bandLab-Header';
import BandLabContent from './bandLab-Content';
import './bandLab.css';

export default class BandLabCurriculum extends Component {
  render() {
    return(
      <div id="bandLab-container">
        <BandLabHeader />
        <BandLabContent />
      </div>
    );
  }
}