import React, { Component } from 'react';
import './traditionalArt-header.css';

class TraditionalArtHeader extends Component {
  render() {
    return(
      <div id="traditionalArtHeader-container">
          <div class="traditionalArtImage-container">
            <div class="traditionalArt-header-image">
              <h1>Traditional Art</h1>
              <img src="https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/header/Traditional+Art+Header.png" alt="traditional art header"/>
            </div>
          </div>
      </div>
    );
  }
}

export default TraditionalArtHeader;