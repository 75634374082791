import React, { Component } from 'react';
import './Programs-Description.css';

export default class ProgramsDescription extends Component {
  render() {
    return(
      <div id="mainPrograms-container">
        <div class="mainPrograms-content-container">
          <div class="mainPrograms-bold">
            <h3>Explore foundry10 Education Programming</h3>
          </div>
          <div class="mainPrograms-normal">
            <p>Our programs are organized across a variety of subject areas and take many forms like assisting with curriculum development and providing after school education programming. While we often cross over between areas and into new spaces, these are how we think about our work right now. Please take a look over them and click on the images to see more about that area and the related programs and projects.</p>
          </div>
        </div>
      </div>
    );
  }
}