import React, { Component } from 'react';
import './vrResources-Header.css';

export default class VrResourcesHeader extends Component {
  render() {
    return(
      <div id="vrResources-header-container">
          <div class="vrResources-header-content-container">
            <h1>Virtual Reality Resources</h1>
          </div>
      </div>
    );
  }
}