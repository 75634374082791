import React, { Component } from 'react';
import StemGoBabyGoHeader from './stemGoBabyGo-Header';
import StemGoBabyGoContent from './stemGoBabyGo-Content';
import './stemGoBabyGo.css';

export default class StemGoBabyGo extends Component {
  render() {
    return(
      <div id="stemGoBabyGo-container">
        <StemGoBabyGoHeader />
        <StemGoBabyGoContent />
      </div>
    );
  }
}