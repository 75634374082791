import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import TranslationInterpretationHeader from './translationInterpretation-Header';
import TranslationInterpretationContent from './translationInterpretation-Content';
import './translationInterpretation.css';

export default class TranslationInterpretation extends Component {
  render() {
    return(
      <div id="translationInterpretation-container">
        <Helmet>
          <title>foundry10 CTE Language Translation and Interpretation Programs</title>
          <meta
            name="title"
            content="foundry10 CTE Language Translation and Interpretation Programs"
          />
          <meta
            name="description"
            content="Discover the growing importance of language translation and interpretation programs in career and technical education (CTE)."
          />
          <meta property="og:title" content="foundry10 CTE Language Translation and Interpretation Programs" />
          <meta
            property="og:description"
            content="Discover the growing importance of language translation and interpretation programs in career and technical education (CTE)."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/translation-and-interpretation" />
          <link rel="canonical" href="https://www.foundry10.org/translation-and-interpretation" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 CTE Language Translation and Interpretation Programs",
                "description": "Discover the growing importance of language translation and interpretation programs in career and technical education (CTE).",
                "url": "https://www.foundry10.org/translation-and-interpretation"
              }
            `}
          </script>
        </Helmet>
        <TranslationInterpretationHeader />
        <TranslationInterpretationContent />
      </div>
    );
  }
}