import React, { Component } from 'react';
import './Review-Header.css';

export default class ReviewHeader extends Component {
  render() {
    return(
      <div id="reviewHeader-container">
        <div class="reviewHeader-image-container">
          <h1>Review</h1>
        </div>
      </div>
    );
  }
}