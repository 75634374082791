import React, { Component } from 'react';
import './Classroom-Application-Header.css';

export default class ClassroomApplicationHeader extends Component {
  render() {
    return(
      <div id="classroomApplication-container">
        <div class="classroomApplication-image-container">
          <h1>Classroom Application</h1>
        </div>
      </div>
    );
  }
}