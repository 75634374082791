import React, { useState, useMemo } from "react";
import { useQuery, gql } from "@apollo/client";

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

//Set Categories
const queryParams = new URLSearchParams(window.location.search);
const cat = queryParams.get("cat");
const themeCat = queryParams.get("themeCat");

let allCats = [cat, themeCat].join(",");
if (allCats == ",") {
  allCats = null;
}

// Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
let searchParam = params.filter;

// Set search param as empty in value not availible
if (!searchParam) {
  searchParam = "";
}

// project cat
const projectCatName = gql`
  query pageQuery($cat_id: [QueryArgument]) {
    categories(group: "projectType", id: $cat_id) {
      title
    }
  }
`;

const ProjectData = (gridData) => {
  const [filterResult, setFilterResult] = useState("");
  const { data, loading, error } = useQuery(projectCatName, {
    variables: {
      cat_id: cat,
    },
  });
  // setCatResult(data)
  if (loading) {
    return <div></div>;
  }
  if (error) {
    return <div>{error}</div>;
  }
  return (
    <React.Fragment>
      {data.categories.length > 0 && (
        <div>
          <p>You filtered for : {data.categories[0].title}</p>
        </div>
      )}
    </React.Fragment>
  );
};

// Get Page content
const entriesQuery = gql`
  query pageQuery(
    $channel: [String]
    $limit: Int!
    $offset: Int!
    $filter: String!
    $themeCat: [QueryArgument]
  ) {
    entries(
      section: $channel
      limit: $limit
      offset: $offset
      search: $filter
      relatedTo: $themeCat
    ) {
      sectionHandle
      title
      slug
      url
      dateCreated @formatDateTime(format: "F j, Y")
      postDate @formatDateTime(format: "F j, Y")
      author {
        fullName
      }
      ... on stories_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
            }
          }
        }
        researchExternalAuthors {
          ... on researchExternalAuthors_BlockType {
            researchExternalAuthorsAuthor
          }
        }
        themes {
          title
        }
        categories {
          title
        }
      }
      ... on resources_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        themes {
          title
        }
        resourceType {
          title
        }
      }
      ... on programs_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        themes {
          title
        }
        interestAreas {
          title
        }
        guidingQuestions {
          title
        }
        audiences {
          title
        }
      }
      ... on projects_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        themes {
          title
        }
        interestAreas {
          title
        }
        guidingQuestions {
          title
        }
        audiences {
          title
        }
        projectType {
          title
        }
      }
      ... on opportunities_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        themes {
          title
        }
        interestAreas {
          title
        }
        guidingQuestions {
          title
        }
      }
      ... on press_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        themes {
          title
        }
        audiences {
          title
        }
        interestAreas {
          title
        }
        guidingQuestions {
          title
        }
      }
    }
    entryCount(section: $channel, search: $filter, relatedTo: $themeCat)
  }
`;

const ArchiveCards = (channel) => {
  const searchFilter = searchParam;
  const { data, loading, error, fetchMore } = useQuery(entriesQuery, {
    variables: {
      channel: channel,
      offset: 0,
      limit: 10,
      filter: searchFilter,
      themeCat: allCats,
    },
  });
  const showLoadMore = useMemo(() => {
    if (data) {
      return data.entryCount > data.entries.length;
    }

    return false;
  }, [data]);

  const onLoadMore = () => {
    fetchMore({
      variables: {
        offset: data.entries.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          entries: [...prev.entries, ...fetchMoreResult.entries],
        });
      },
    });
  };

  if (loading) return null;
  if (error) return `Error! ${error}`;

  const currentUrl = window.location.href;

  return (
    <div>
      {searchFilter == "" ? (
        <div></div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "25px",
          }}
        >
          <p>You searched for : {searchFilter} </p>
          <button
            className="button "
            style={{
              background: "transparent",
              color: "#007A8A",
              border: "1px solid",
              padding: "12px",
              lineHeight: "normal",
              fontSize: "13px",
              height: "40px",
            }}
            onClick={() =>
              window.location.replace(window.location.href.split("?")[0])
            }
          >
            Clear Filter
          </button>
        </div>
      )}
      {data.entries.map((block, index) => {
        let category = null;
        // Set Category(Story Ttle)
        if (block.categories && block.categories.length) {
          category = block.categories[0].title;
        }

        let projectType = "";
        if (block.projectType?.length > 0) {
          projectType = block.projectType[0].title;
        }

        return (
          <div key={index} className="archiveCard large">
            <div className="imageContainer">
              <img
                src={
                  block.featuredImage[0]
                    ? block.featuredImage[0].url
                    : "/news-archive-spacer.png"
                }
                alt={block.title}
              />
            </div>
            <div className="archiveListContent">
              {category && (
                <div className="cardCat">
                  <h5>{category}</h5>
                </div>
              )}
              {projectType && (
                <div className="cardCat">
                  <h5>{projectType}</h5>
                </div>
              )}
              <h3>
                <a href={`${currentUrl.split("?filter=")[0].split("?cat=")[0]}/${block.slug}`}>{block.title}</a>
              </h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: block.blurb,
                }}
              ></div>
              <p className="text-body_bold textGray">{block.postDate}</p>
            </div>
          </div>
        );
      })}
      {showLoadMore && (
        <button className="button loadMore" onClick={onLoadMore}>
          Load More
        </button>
      )}
    </div>
  );
};

// const ProjectList = ({ channel }) => (
//   <section id="archiveList">
//     <div className="container">
//       <ArchiveCards channel={channel} />
//     </div>
//   </section>
// );

const ProjectList = ({ channel }) => {
  const searchFilter = searchParam;
  return (
    <div>
     <section id="archiveList">
          <div className="container">
            {cat == null ? (
              <div></div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "25px",
                }}
              >
                <div>
                  <ProjectData />
                </div>
                <button
                  className="button "
                  style={{
                    background: "transparent",
                    color: "#007A8A",
                    border: "1px solid",
                    padding: "12px",
                    lineHeight: "normal",
                    fontSize: "13px",
                    height: "40px",
                  }}
                  onClick={() =>
                    window.location.replace(window.location.href.split("?")[0])
                  }
                >
                  Clear Filter
                </button>
              </div>
            )}
            <ArchiveCards channel={channel} />
          </div>
        </section>
    </div>
  );
};

export default ProjectList;