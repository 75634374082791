import React, { Component } from 'react';
import './culinary-Header.css';

export default class CulinaryHeader extends Component {
  render() {
    return(
      <div id="culinary-header-container">
          <div class="culinary-header-content-container">
            <h1>Culinary</h1>
          </div>
      </div>
    );
  }
}