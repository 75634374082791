import React, { Component } from 'react';
import SavvyLadiesHeader from './savvyLadies-Header';
import SavvyLadiesContent from './savvyLadies-Content';
import './savvyLadies.css';

export default class SavvyLadies extends Component {
  render() {
    return(
      <div id="savvyLadies-container">
        <SavvyLadiesHeader />
        <SavvyLadiesContent />
      </div>
    );
  }
}