import React, { Component } from 'react';
// import './projectsLandingPage-Links.css';

export default class CulinaryLinks extends Component {
  render() {
    return(
      <div id="projectsLandingPage-links-container">
        <div class="projectsLandingPage-links-content-container">
          <div class="interestArea-cardsList2-container">
            <div class="interestArea-domain-title">
              <h2>Program Content</h2>
            </div>
            <ul id="interestArea-cardsList2" class="interestArea-events-list2">
              <li>
                <a href="https://medium.com/the-foundry10-voice/still-cooking-how-culinary-arts-programs-adapted-to-remote-learning-30ce235113f6" target="_blank" rel="noopener noreferrer">
                  <div class="student-work-card-container">
                    <img src="https://f10-programs.s3.us-west-2.amazonaws.com/trades/Still+Cooking+Culinary+Blog+Post.png" alt="still cooking culinary trades blog post link" />
                    <p id="Everyday Unseen" class="student-work-card-title">Still Cooking: How Culinary Arts Programs Adapted to Remote Learning</p>
                    <div id="Everyday Unseen" class="student-work-card-overlay"></div>
                 </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}