import React, { Component } from 'react';
import AdolescentsLandingPageDescription from './adolescentsLandingPage-Description';
import AdolescentsLandingPageLinks from './adolescentsLandingPage-Links';
import NewFooter from '../../../../pages-other/NewFooter';
import './adolescentsLandingPage-Content.css';

export default class AdolescentsLandingPageContent extends Component {
  render() {
    return(
      <div id="adolescentsLandingPage-content-container">
        <AdolescentsLandingPageDescription />
        <AdolescentsLandingPageLinks />
        <NewFooter />
      </div>
    );
  }
}