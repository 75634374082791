import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import './threeDMixedMedia-cards.css';

class ThreeDMixedMediaCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
        visible: false,
        name: '',
        projectTitle: '',
        modalProjectTitle: '',
        studentBio: '',
        projectBio: '',
        cardImg: '',
        userImg: '',
        imgUrl: '',
        altText: ''
    }
  }
  
  openModal() {
    this.setState({
        visible: true
    });
  }

  closeModal() {
    this.setState({
        visible: false
    });
  }

  render() {
  const finalCards = [
    {
      'name': 'Ayana Muwwakkil',
      'projectTitle': 'I Am Human',
      'modalProjectTitle': 'I Am Human',
      'studentBio': 'Ayana Muwwakki is a sophomore high school student enrolled in Studio Art at Shorecrest. Inspired by the Figuring History Exhibit at the Seattle Art Museum, Ayana wanted to look back on the history of being an African American. This piece was based off of The Civil Rights Movement, and as an African Artist she wanted to join the movement of African American artists who are influencing people’s views on African Culture.',
      'projectBio': 'This piece is a mixed media collage, this represents the diversity of people that contributed to the civil rights movement.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/3D+%26+mixed+media/Ayana+Muwwakkil.png',
      'altText': "Collage of photographs from the Civil Rights movement behind two mixed media portraits of African American activists."
    },
    {
      'name': 'Eowyn Morningstar',
      'projectTitle': 'Altar',
      'modalProjectTitle': 'Altar',
      'studentBio': 'Éowyn Morningstar is a junior at the alternative Nova High School in Seattle, WA. Completely self-taught, he has been making art seriously for four years, but has been involved with art his whole life. Éowyn’s primary focus is comics and the expression of self through distorted and non-traditional ways. After graduating, he hopes to go directly into the comics’ industry and start spreading his art and stories to the world.',
      'projectBio': 'Altar is an interactive room that opens the doors to peek at my emotions. This piece is inspired by the late Rachel Feferman’s Room. I wanted to combine collage and traditional drawing to show my many emotions and what forms they take. I hope those who view it can become more connected with the emotions they feel.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/3D+%26+mixed+media/Eowyn+Morningstar.png',
      'altText': "Mixed media triboard collage depicting walls of a room with images of mountains, sea creatures, and plants on them."
    },
    {
      'name': 'Eva Smerekanych',
      'projectTitle': 'Free',
      'modalProjectTitle': 'Free',
      'studentBio': 'Eva Smerekanych is a junior at Newport High School in Bellevue, WA. She is enrolled in AP 3D Art, creating a portfolio on humans in motion. Inspired by split-second moments in life, Eva mainly uses hand-sculpted clay to capture motion. In her piece, Free, Eva wanted to express the bliss one feels when they release their tension, relax, and lean into the wind. After graduating in the spring of 2019, Eva hopes to contribute to pursue art throughout college.',
      'projectBio': 'Woman wearing long, flowing dress and a headscarf, stretching out her arms to either side with her head thrown back, leaning into the wind.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/3D+%26+mixed+media/Eva+Smerekanych.png',
      'altText': "3D white clay sculpture of a figure with arms outstretched, head back, and flowing dress blown back behind them."
    },
    {
      'name': 'Hosana Zewoldi',
      'projectTitle': 'Representation Matters',
      'modalProjectTitle': 'Representation Matters',
      'studentBio': 'I made this collage of beautiful black women because society has judged them for too long! Rather our skin is too dark, our hair is too nappy or isn’t appearance worthy, or our features are pointed out as imperfect, I think black is beautiful! We’ve been judged and under appreciated for too long, and growing up thinking people who looked like myself were not portrayed as beautiful can affect a child. That is why Representations Matters!',
      'projectBio': 'An apron with multiple cut outs of black beauty showing all the beautiful shades, looks, and cultural backgrounds of colored women.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/3D+%26+mixed+media/Hosana+Zewoldi.png',
      'altText': 'Black apron with pink straps decorated with the text "Queen" and a collage of images of Black women.'
    },
    {
      'name': 'Isabella (Izzy) Des Roches',
      'projectTitle': 'Cracked Heart',
      'modalProjectTitle': 'Cracked Heart',
      'studentBio': 'Isabella (Izzy) Des Roches is a senior at Newport High School in Bellevue, WA. She is currently in AP 3D Art, a college level 3-dimensional sculpture class. After graduating in June, Des Roches will be studying Human Psychology at the University of Oregon where she will focus on her pathway to veterinary school.',
      'projectBio': 'Cracked Heart was created a year after Des Roches has heart surgery for Supraventricular tachycardia (SVT) when she was a freshman. For her, this piece is a memory for the surgery as well as being SVT free for now three years. This piece was made with Raku clay as well with crackle glaze. The piece shows the anatomical structure of the heart but also its beauty in delicate vein work.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/3D+%26+mixed+media/Isabella+Des+Roches.png',
      'altText': "3D white clay sculpture of an anatomical human heart covered in intricate gray veins."
    },
    {
      'name': 'Judy Kim',
      'projectTitle': 'Naginata Bo',
      'modalProjectTitle': 'Naginata Bo',
      'studentBio': 'Judy Kim is a freshman at Thomas Jefferson High School in Federal Way, WA. A curious artist created this piece influenced by Japanese history. Now transitioning into high school and learning Japanese as a new language and its culture enlightened her curiosity in ancient Japanese history. Her curiosity sparkles along with the enthusiasm she poured into this piece.',
      'projectBio': 'The Naginata is a hand forged, traditionally made Japanese bladed pole or spear. These weapons were originally used by Sohei (warrior monks), which are used during the Edo period (1603-1868) overtime, the weapon is used by women to protect their homes while members of their family are off on the battlefield.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/3D+%26+mixed+media/Judy+Kim.png',
      'altText': "Japanese style Naginta blade with red ribbon where the blade meets the yellow handle."
    },
    {
      'name': 'Kaeylob Johnson',
      'projectTitle': 'Everyday Black',
      'modalProjectTitle': 'Everyday Black',
      'studentBio': 'This art piece was made to show how a society that stereotypes brings no benefit to our world. This art piece was created to show that not all African American men are thieves. This stereotype is a form of ignorance and it separates us as people. This piece shows an African American male standing in a very fancy clothing store being watched from all angles. As an African American male, Johnson feels as if he’s targeted every time he walks into a store because of the color of his skin. This feeling is even more extreme when Kaeylob goes into a fancy store. He hopes this piece makes people think twice before they judge.',
      'projectBio': 'Represents an African American male in a designer shop being watched.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/3D+%26+mixed+media/Kaeylob+Johnson.png',
      'altText': "Mixed media display showing an African American male in a hoodie in a Gucci shop."
    },
    {
      'name': 'Kristie Martz',
      'projectTitle': 'Brain',
      'modalProjectTitle': 'Brain',
      'studentBio': 'Kristie Martz is a senior at Newport High School in Bellevue, WA. She is currently enrolled in AP 3D Art, a college level art class. Her concentration is focused on capturing the mind and what goes on inside of it. In college she hopes to study the brain.',
      'projectBio': 'This piece is a brian with a surgical slit on the side that allows you to see the neural pathways inside.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/3D+%26+mixed+media/Kristie+Martz.png',
      'altText': "Glazed red 3D sculpture of a human brain with a slit revealing gold wired neural pathways underneath."
    },
    {
      'name': 'Mollie McCarthy',
      'projectTitle': 'Caught in the Deep End',
      'modalProjectTitle': 'Caught in the Deep End',
      'studentBio': 'Mollie McCarthy is a senior at Newport High School in Bellevue, WA. She is enrolled in AP 3D Art developing her concentration. Her overall concentration messages aims to show the expectation vs reality of Disney movies and how they are portrayed in today’s society. This piece was inspired by The Little Mermaid. McCarthy’s goal and vision for this work was to show Ariel’s wish to be human and have legs and fall in love with a prince comes with consequences and gets caught in the middle. Mollie plans to attend Western Washington University in the Fall.',
      'projectBio': 'This piece is a ceramic sculpture of a half mermaid, half human being stitched together.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/3D+%26+mixed+media/Mollie+McCarthy.png',
      'altText': "Ceramic sculpture of a gray-white human leg and foot bloodily stitched to a green scaled mermaid tail."
    },
    {
      'name': 'Sebastian Robbins',
      'projectTitle': 'Monster Kids',
      'modalProjectTitle': 'Monster Kids',
      'studentBio': 'Sebastian Robbins is currently attending The Center School, after which they will go to pursue a design degree focused on fashion, particularly the styles of Lolita and Fairy Kei. They hope that someday clothes will not have perceived gender limitations and that their fashion can help others express their gender identity and personal style.',
      'projectBio': 'Two pieces of altered clothing. One is black and white striped. The other is a pastel color.The black and white tank top has three red pieces sewn into scratches across the chest while the pants are constructed to be in the harem pants fashion.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/3D+%26+mixed+media/Sebastian+Robbins.png',
      'altText': "Model exhibiting a black and white striped tank top with red slashes down the front."
    },
    {
      'name': 'Sophie Engle',
      'projectTitle': 'Happy Hour',
      'modalProjectTitle': 'Happy Hour',
      'studentBio': 'Sophie Engle attends Shorecrest High School in Shoreline, WA, where she has taken art classes throughout her four years. She focuses on collage, drawing inspiration from the political climate, nature, and literature. She looks forward to continuing her art career over the next four years at college.',
      'projectBio': 'Paper collage on a clock with a drinking motif.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/3D+%26+mixed+media/Sophie+Engle.png',
      'altText': "Paper collage of figures drinking or holding drinks fixed on the face of a wooden-rimmed clock."
    },
    {
      'name': 'Yulia Groysman',
      'projectTitle': 'Wiggly Snake',
      'modalProjectTitle': 'Wiggly Snake',
      'studentBio': 'Yulia Groysman is a junior at Newport High School. Groysman is a swimmer so most of their tiem is spent in the pool. However, when outside of the water, they like to spend time with friends and go on fun trips with their family.',
      'projectBio': 'This is a toy called a wiggly snake that many kids have played with in their childhood. However, in this particular piece it starts to take on another from of a real snake. The slithering tongue, the black, glassy eyes, and the green scale-like skin give it a dark sense of how a childs innocence slowly disappears as they get older and start to see the world for what it truly is.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/3D+%26+mixed+media/Yulia+Groysman.png',
      'altText': "3D wooden snake toy poised with its green head raised, red tongue out, and unpainted white tail curled."
    }
  ];

  const allCardData2 = finalCards.map((records, index) => (
    <li 
      key={index} 
      onClick={() => {
      this.openModal();
      this.setState({
        name: records.name,
        projectTitle: records.projectTitle,
        modalProjectTitle: records.modalProjectTitle,
        studentBio: records.studentBio,
        projectBio: records.projectBio,
        cardImg: records.cardImg,
        userImg: records.userImg,
        imgUrl: records.imgUrl,
        altText: records.altText
      });
    }}>
      <div class="final-cards-container">
        <img src={records.cardImg} alt={records.altText} />
        <p id={records.name} class="final-cards-title">{records.name}</p>
        <div id={records.name} class="final-cards-overlay"></div>
        <div class="final-cards-button"><span id={records.name} class="final-cards-popup-title">{records.projectTitle}</span></div>
      </div>
    </li>
  ));
    return (
      <div id="Cards-Data2-Container">
        <div class="cardsList2-container">
        <ul id="cardsList2" class="events-list2">
          {allCardData2}
        </ul>
        <section>
                <Modal 
                    visible={this.state.visible}
                    width="93%"
                    height="80%"
                    effect="fadeInDown"
                    onClickAway={() => this.closeModal()}
                >
                    <div class="art-close-container">
                      <div class="art-popup-close">
                        <button onClick={() => this.closeModal()}>
                          <img src="https://s3-us-west-2.amazonaws.com/f10-image/X-icon.png" alt="X close tag"/>
                        </button>
                      </div>
                    </div>
                    <div id="artModal-container">
                      <div class="artModal-content-container">
                        <div class="artModal-image-container">
                         <img src={this.state.cardImg} alt={this.state.altText}></img>
                        </div>
                        <div class="artModal-description-container">
                          <h1>{this.state.name}</h1>
                          <p>{this.state.studentBio}</p>
                          <h2>{this.state.modalProjectTitle}</h2>                
                          <p>{this.state.projectBio}</p> 
                        </div>
                      </div>
                    </div>
                </Modal>
            </section>
          </div>
      </div>
    );
  }
}

export default ThreeDMixedMediaCards;