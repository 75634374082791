import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import BeyondBoundariesHeader from './beyondBoundaries-Header';
import BeyondBoundariesContent from './beyondBoundaries-Content';
import './beyondBoundaries.css';

export default class BeyondBoundaries extends Component {
  render() {
    return(
      <div id="beyondBoundaries-container">
        <Helmet>
          <title>Annual Dance Workshop and Showcase: Beyond Boundaries</title>
          <meta
            name="title"
            content="Annual Dance Workshop and Showcase: Beyond Boundaries"
          />
          <meta
            name="description"
            content="Explore foundry10's annual Beyond Boundaries dance program, designed to empower youth through innovative learning experiences."
          />
          <meta property="og:title" content="Annual Dance Workshop and Showcase: Beyond Boundaries" />
          <meta
            property="og:description"
            content="Explore foundry10's annual Beyond Boundaries dance program, designed to empower youth through innovative learning experiences."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/beyond-boundaries" />
          <link rel="canonical" href="https://www.foundry10.org/beyond-boundaries" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Annual Dance Workshop and Showcase: Beyond Boundaries",
                "description": "Explore foundry10's annual Beyond Boundaries dance program, designed to empower youth through innovative learning experiences.",
                "url": "https://www.foundry10.org/beyond-boundaries"
              }
            `}
          </script>
        </Helmet>
        <BeyondBoundariesHeader />
        <BeyondBoundariesContent />
      </div>
    );
  }
}