import React, { Component } from 'react';
import './OurStory-IllustrationThree.css';

export default class OurStoryIllustrationThree extends Component {
  render() {
    return(
      <div id="ourStory-third-headerImage-container">
      <div class="ourStory-third-container">
        <div class="ourStory-third-header-image">
          <img src="https://f10-misc.s3-us-west-2.amazonaws.com/Our+Story+Illustration+Three.png" alt="teacher and students in classroom"/>
        </div>
      </div>
      <div class="ourStory-third-text-container">
        <div class="ourStory-third-realTitle-container">
          <h1>foundry10 root principles</h1>
        </div>
        <div class="ourStory-third-title-container"/>
        <div class="text-box-one-third">
          <p>Since that time, foundry10’s reach has grown and expanded. Though we now cover a broad array of educational areas, our work is grounded in simple, powerful beliefs.</p>
        </div>
        <div class="text-box-two-third">
          <ul class="rootPrinciples">
            <li>We believe in thinking flexibly, iterating on ideas, and gathering feedback from teachers and students as their voices form the foundation of our work.</li>
            <li>We believe that hiring a staff with diverse life and educational experiences is of vital importance if we are going to tackle real issues with regard to learning.</li>
            <li>We believe that every employee should have regular contact with students in order to ground us in our work and keep us focused on what matters: kids’ learning.</li>
            <li>We believe learning research should create real value for students and teachers.</li>
            <li>Finally, we believe in removing as many barriers as possible to create valuable experiences for children and the adults who work with them.</li>
          </ul>
        </div>
        <div class="text-box-three-third">
          <p>From the start, foundry10 has aimed to work more effectively by simultaneously engaging in dynamic research, youth programs and philanthropy. Working across these areas, and sharing what we learn from all three, has allowed us to make a meaningful impact in each. Today, this work continues as we strive to better understand the ways we learn and create value for kids in our own, unique, way.</p>
        </div>
      </div>
  </div>
    );
  }
}