import React, { Component } from 'react';
import './vrLandingPage-Description.css';

export default class VrLandingPageDescription extends Component {
  render() {
    return(
      <div id="vrLandingPage-description-container">
        <div class="vrLandingPage-description-content-container">
          <p>During our Applied VR study, foundry10 consistently heard one piece of feedback from educators: how do we find content for our students?</p>
          <p>Especially with the introduction of new, more affordable headsets and equipment, there’s been an explosion of new applications for VR and AR platforms. For educators, this brings a huge opportunity to engage students in new ways, but it does come with some challenges. Experiences are spread across multiple storefronts and included in huge lists of content that can be difficult to parse. Finding the right content in this field is expensive in terms of both time and money.</p>
          <p>We are committed to creating useful resources to solve challenges like this. The following is a library of VR titles we have reviewed and sorted into focal areas like Art, Math, Science, and more that have been reviewed by real students, right here at foundry10. Additionally, we are working to highlight the need for learning-focused content and will be posting more resources like interviews with VR developers, commentary on the state of learning-based play, and other useful things. Take a look below to find our lists of content, and please reach out to us if there is anything you want to see that we not currently have.</p>
        </div>
      </div>
    );
  }
}