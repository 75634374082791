import React, { Component } from 'react';
// import TranslationInterpretationIntro from './translationsInterpretation-Intro';
import TranslationInterpretationDescription from './translationInterpretation-Description';
// import TranslationInterpretationLinks from './translationsInterpretation-Links';
import NewFooter from '../../../pages-other/NewFooter';
import './translationInterpretation-Content.css';

export default class TranslationInterpretationContent extends Component {
  render() {
    return(
      <div id="translationInterpretation-content-container">
        {/* <TranslationInterpretationIntro /> */}
        <TranslationInterpretationDescription />
        {/* <TranslationInterpretationLinks /> */}
        <NewFooter />
      </div>
    );
  }
}