import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
// import Chip from '@material-ui/core/Chip';
import ReactPlayer from 'react-player';
import ReviewHeader from './Review-Header';
import ClassroomApplicationHeader from './Classroom-Application-Header';
import VrPlatforms from './VR-Platforms';
import './VRBlog.css';
import NewFooter from '../../pages-other/NewFooter';


export default class VRBlog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      blog: {}
    };
  }
  

  componentDidMount() {
    const nameShort = this.props.props.match.params.name;
    axios.post('/virtualteachers/getIndividReview/'+nameShort, {
      data: nameShort
    }).then(data => {
        this.setState({
          blog: data.data
        })
    }).catch(err => {
      console.log('err in /getVrReview: ', err);
    })
  }

  render() {
    if(this.state.blog) {
      return(
        <div id="vrBlog-main-container">
          <Helmet>
            <title>{this.state.blog.seoTitle}</title>
            <meta
              name="title"
              content={this.state.blog.seoTitle}
            />
            <meta
              name="description"
              content={this.state.blog.seoDescription}
            />
            <meta property="og:title" content={this.state.blog.seoTitle} />
            <meta
              property="og:description"
              content={this.state.blog.seoDescription}
            />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={this.state.blog.seoUrl} />
            <link rel="canonical" href={this.state.blog.seoUrl} />
            
            <script type="application/ld+json">
              {`
                {
                  "@context": "https://schema.org",
                  "@type": "WebPage",
                  "name": "${this.state.blog.seoTitle}",
                  "description": "${this.state.blog.seoDescription}",
                  "url": "${this.state.blog.seoUrl}"
                }
              `}
            </script>
          </Helmet>
          {/* MAIN */}
          <div id="vrBlog-main-content-container">
            {/* HEADER */}
            <div id="vrBlog-header-container">
              <div class="vrBlog-header-title-container">
                <h3>Virtual Reality Games</h3>
                <h2>{this.state.blog.name}</h2>
                <p>{this.state.blog.summary}</p>
                <div class="vrBlog-headerImage-container">
                  <div class="vrBlog-headerImage-content-container">
                    <img src={this.state.blog.imageUrl} alt="vr game poster"/>
                  </div>
                </div>
              </div>
              <div class="vrBlog-header-content-container">
                <div class="vrBlog-video-container">
                  <ReactPlayer 
                    url={this.state.blog.videoUrl}
                    class='react-player'
                    controls
                    width='100%'
                    height='100%'
                  />
                </div>
              </div>
            </div>
            {/* BODY */}
            <div id="vrBlog-review-container">
              <div class="vrBlog-review-content-container">
                {/* REVIEW */}
                <ReviewHeader />
                <div class="vrBlog-review-description-container">
                  <h4>Reviewed by {this.state.blog.reviewedBy},</h4>
                  <h4>{this.state.blog.reviewDate}</h4>
                </div>
                <div class="vrBlog-review-blurb">
                  <p>{this.state.blog.review}</p>
                </div>
                <div class="vrBlog-review-circle">
                  <div class="vrBlog-review-engagement">
                    <img src={this.state.blog.engagement} alt="meter rating level of engagement"/>
                  </div>
                  <div class="vrBlog-review-usefulness">
                    <img src={this.state.blog.usefulness} alt="meter rating level of usefulness"/>
                  </div>
                </div>
                <div class="vrBlog-review-key">
                  <div class="review-key">
                    <div class="dot-container">
                      <span class="dot-green"></span>
                    </div>
                    <div class="dotDescription">
                      <p>This experience is highly relevant to classrooms or is very engaging for students.</p>
                    </div>
                  </div>
                  <div class="review-key">
                    <div class="dot-container">
                      <span class="dot-yellow"></span>
                    </div>
                    <div class="dotDescription">
                      <p>There may be some subjective classroom potential, or will require extra support to keep students engaged.</p>
                    </div>
                  </div>
                  <div class="review-key">
                    <div class="dot-container">
                      <span class="dot-red"></span>
                    </div>
                    <div class="dotDescription">
                      <p>This experience isn’t relevant, or won’t keep students engaged for long.</p>
                    </div>
                  </div>
                </div>
                {/* CLASSROOM APPLICATION */}
                <ClassroomApplicationHeader />
                <div class="vrBlog-classroomApplication-blurb">
                  <p>{this.state.blog.classroomApplication}</p>
                </div>
                {/* PLATFORMS */}
                <VrPlatforms data={this.state.blog} all={this.state.blog.platform}/>
                {/* WIDGET */}
                <div class="vrBlog-widget-container">
                  <iframe class="steamWidget" src={this.state.blog.steamWidget} title="steam widget" frameborder="0" width="646" height="190"></iframe>
                </div>
                


                {/* FILTER TAGS */}
                {/* <div class="vrBlog-tags-container">
                <Chip
        size="medium"
        color="primary"
        style={{width: 140, background: 'linear-gradient(to right bottom, #430089, #82ffa1)', fontFamily: 'Helvetica Neue Light'}}
        label="Clickable Link Chip"
        className={classes.chip}
        component="a"
        href="/research"
        clickable
      />
                </div> */}
              </div>
            </div>
            <NewFooter />
          </div>
        </div>
      );
    } else {
      return (
        <p className='VisibilityOff'>

        </p>
      )
    }
  }
}