import React, { Component } from 'react';
import BrierTerraceHeader from './brierTerrace-Header';
import BrierTerraceContent from './brierTerrace-Content';
import './brierTerrace.css';

export default class BrierTerrace extends Component {
  render() {
    return(
      <div id="brierTerrace-container">
        <BrierTerraceHeader />
        <BrierTerraceContent />
      </div>
    );
  }
}