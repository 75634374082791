import React, { Component } from 'react';
import './OurStory-IllustrationOne.css';

export default class OurStoryIllustrationOne extends Component {
  render() {
    return(
      <div id="ourStory-first-headerImage-container">
      <div class="ourStory-first-container">
        <div class="ourStory-first-header-image">
          <img src="https://f10-misc.s3-us-west-2.amazonaws.com/Our+Story+Illustration+One.png" alt="lisa in math classroom" />
        </div>
      </div>
      <div class="ourStory-first-text-container">
        <div class="ourStory-first-realTitle-container">
          <h1>How do we make a greater impact?</h1>
        </div>
        <div class="ourStory-first-title-container"/>
        <div class="text-box-one-first">
          <p>In 2013, Lisa Castaneda was working as a math teacher at a fantastic school in Seattle. She had just completed her Master’s degree in education and, after a decade of teaching in a K-8 setting, was looking to do work that would make a greater impact than was possible in just one school. She was acutely aware of the challenges that teachers and students were facing in complex, modern-day classrooms. Lisa wondered if there might be a way to collaboratively approach those issues with the educators who know their students best, and use research to better connect these findings to actual classroom practices.</p>
        </div>
        <div class="text-box-two-first">
          {/* <h2>"Here is a sample quote that someone said."</h2>
          <h3>-Lisa Casteneda</h3> */}
        </div>
      </div>
  </div>
    );
  }
}