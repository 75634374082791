import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import './digitalPrint-cards.css';

class DigitalPrintCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
        visible: false,
        name: '',
        projectTitle: '',
        modalProjectTitle: '',
        studentBio: '',
        projectBio: '',
        cardImg: '',
        userImg: '',
        imgUrl: '',
        altText: ''
    }
  }
  
  openModal() {
    this.setState({
        visible: true
    });
  }

  closeModal() {
    this.setState({
        visible: false
    });
  }

  render() {
  const finalCards = [
    {
      'name': 'Ace Huang',
      'projectTitle': 'On Hold',
      'modalProjectTitle': 'On Hold',
      'studentBio': 'Ace Huang is a high school junior at Franklin High School in Seattle, Washington. They grew up drawing and taking free art classes at Pratt Art Studios in Seattle and finished advanced art in high school. Though classically trained in pencil drawing and painting, Huang began exploring graphics and digital art when they stopped attending school due to medical complications. Huang coordinated patterns with visceral imagery to convey emotion linked to mental health and personal conflicts with family. After graduating, Huang intends to attend Pratt to study graphic design.',
      'projectBio': 'Small postcard-sized prints inked by hand and reprinted on cardstock. Metal buttons with illustrations originally inked as well.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/digital+%26+print/Ace+Huang.png',
      'altText': "Cartoon illustrated postcards, one with blue hands connected with red lines, and one with blue eyes and red eyelashes."
    },
    {
      'name': 'Alexandra McDowell',
      'projectTitle': 'High Voltage',
      'modalProjectTitle': 'High Voltage',
      'studentBio': '',
      'projectBio': 'This piece was shot in August 2017 as a part of Alexandra’s “High Voltage” series, about femininity and vanity. Alexandra loves the highlight work of this piece and the fact that many of her favorite shots come from her own bathroom.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/digital+%26+print/Alexandra+McDowell.png',
      'altText': "Portrait photograph in low-light of a figure leaning backwards over a bathtub."
    },
    {
      'name': 'Alice Wang',
      'projectTitle': 'Strength',
      'modalProjectTitle': 'Strength',
      'studentBio': 'Alice Wang is a freshman at Bellevue High School. She is predominantly self-taught, although attending a beginner drawing class in her school. Her artwork is uniquely digital, drawn using apps on her phone. Her focus is primarily on conveying themes through portraits, typically connecting to intangible concepts, aiming to engage the viewer in a more thoughtful piece. Though still young, Alice aspires to attend Rhode Island School of Design.',
      'projectBio': 'This drawing depicts a grove where a woman sits with a tiger, symbolizing the power of strength when tamed. There is another in the shadows, and all three subjects are directly looking at the viewer. They appear firm and mysterious, but not quite threatening, contributing to the expression of the title, Strength.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/digital+%26+print/Alice+Wang.png',
      'altText': "Digital drawing of a figure with long, dark, wavy hair looking straight on with green palm leaves in the background."
    },
    {
      'name': 'Elias Papadimas',
      'projectTitle': 'Feel the Need, The Need for Speed',
      'modalProjectTitle': 'Feel the Need, The Need for Speed',
      'studentBio': 'Elias Papadimas is a senior at Thomas Jefferson High School with a love for history, aviation, and photography. This is the story of how it all came together: Watching an airplane sit in a museum may not a big deal to most, but to those it inspires and excites. It is humbling to see such a bird that used to scream across the sky laid to rest. I wanted to capture Tomcats final mission on standby to inspire the next generation of young aviators.',
      'projectBio': 'A silhouette of America’s former Top Gun fighter in her final resting place inspiring America’s future aviators.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/digital+%26+print/Elias+Papadimas.png',
      'altText': "Photographed silhouette of the nose of a Top Gun fighter plane in a hanger."
    },
    {
      'name': 'Esther Angeles',
      'projectTitle': 'Sheng Xiao',
      'modalProjectTitle': 'Sheng Xiao',
      'studentBio': 'Esther Angeles is a student at Franklin High School who is graduating with the class of 2018. She has taken Drawing and Painting 1 and 2 as well as beginners Ceramics class in the past and has continued to use her creativity outside the classroom by frequently sketching and coloring in her sketchbook during her free time. Inspired by artists online, Angeles implements the FireAlpaca and iMovie programs to create other pieces of art such as colored digital art and animatics.',
      'projectBio': 'My art piece consists of two posters with six zodiac animals on each poster. Behind each animal there are corresponding “lucky” colors. The pattern and placement of these colors depend on the mood or personality of the zodiac sign itself.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/digital+%26+print/Esther+Angeles.png',
      'altText': "Digital drawings of a horse, ram, monkey, and rooster in separate quadrants. The animals are in grayscale with colorful backdrops."
    },
    {
      'name': 'Mimi Lucking',
      'projectTitle': 'N 49° 10’ 40” W 123°',
      'modalProjectTitle': 'N 49° 10’ 40” W 123°',
      'studentBio': 'Mimi Lucking is a senior in high school studying at South Seattle College. After graduating with an A.S. in the spring of 2018, she plans to pursue a biology major with a concentration in ecology. She is fascinated by the intersections between art and science, especially the shared aspects of observation and documentation. In her work, she aims for simplicity and tranquility as well as expression of joy. Lucking’s photography draws together people and the environments that support them.',
      'projectBio': 'A collaged Venn diagram shot on film and assembled digitally.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/digital+%26+print/Mimi+Lucking.png',
      'altText': "Black and white venn diagram of film shots digitally collaged. The bottom right shows two figures lying on a rocky beach."
    },
    {
      'name': 'Rianne Nelwan',
      'projectTitle': 'Recollection, Masc Collection',
      'modalProjectTitle': 'Recollection, Masc Collection',
      'studentBio': 'Rianne Nelwan is a junior at Mariner High School in Everett, WA. Inspired by the idea of identity, she first set to capture its complexity in portrait photography and later begun creating short films to do the same. She has been helped along her journey by her sister’s support and best friend’s camera. Once graduated in 2019, Rianne plans on attending the University of Washington to major in Pre Arts.',
      'projectBio': 'Aiming to capture the idea of identity through her lens, the Recollection portraits focus on the concept of impressionability in our youth while the Masc Collection highlights the different forms of masculinity.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/digital+%26+print/Rianne+Neiwan.png',
      'altText': "Collage of 5 portrait photos examining different perspectives and identities in each image."
    },
    {
      'name': 'Simone Williams',
      'projectTitle': 'The Honeyman',
      'modalProjectTitle': 'The Honeyman',
      'studentBio': 'Simone R. Williams is a junior at Big Picture High School in Burien, WA. Williams’ is a predominantly self-taught digital artist with a passion for visual storytelling. She takes inspiration from the cartoons and comics she consumed in her youth, and hopes to further popularize heavily character-driven narratives. After graduation in 2019, Williams plans to attend The Evergreen State College and pursue the independent publication of graphic novels.',
      'projectBio': 'The Honeyman is a half-eaten, zombie-like person suspended in honey, the substance that preserves his upper half, and that he now controls. He is still conscious, if not altogether alive, thanks to the soul energy he shares with the bees that have taken residence in his chest. The Honeyman as a piece is a representational of his anguish.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/digital+%26+print/Simone+Williams.png',
      'altText': "Digital graphic art of a partially zombified figure looking anguished while suspended in a honey-colored bubble."
    }
  ];

  const allCardData2 = finalCards.map((records, index) => (
    <li 
      key={index} 
      onClick={() => {
      this.openModal();
      this.setState({
        name: records.name,
        projectTitle: records.projectTitle,
        modalProjectTitle: records.modalProjectTitle,
        studentBio: records.studentBio,
        projectBio: records.projectBio,
        cardImg: records.cardImg,
        userImg: records.userImg,
        imgUrl: records.imgUrl,
        altText: records.altText
      });
    }}>
      <div class="final-cards-container">
        <img src={records.cardImg} alt={records.altText} />
        <p id={records.name} class="final-cards-title">{records.name}</p>
        <div id={records.name} class="final-cards-overlay"></div>
        <div class="final-cards-button"><span id={records.name} class="final-cards-popup-title">{records.projectTitle}</span></div>
      </div>
    </li>
  ));
    return (
      <div id="Cards-Data2-Container">
        <div class="cardsList2-container">
        <ul id="cardsList2" class="events-list2">
          {allCardData2}
        </ul>
        <section>
                <Modal 
                    visible={this.state.visible}
                    width="93%"
                    height="80%"
                    effect="fadeInDown"
                    onClickAway={() => this.closeModal()}
                >
                    <div class="art-close-container">
                      <div class="art-popup-close">
                        <button onClick={() => this.closeModal()}>
                          <img src="https://s3-us-west-2.amazonaws.com/f10-image/X-icon.png" alt="X close tag"/>
                        </button>
                      </div>
                    </div>
                    <div id="artModal-container">
                      <div class="artModal-content-container">
                        <div class="artModal-image-container">
                         <img src={this.state.cardImg} alt={this.state.altText}></img>
                        </div>
                        <div class="artModal-description-container">
                          <h1>{this.state.name}</h1>
                          <p>{this.state.studentBio}</p>
                          <h2>{this.state.modalProjectTitle}</h2>                
                          <p>{this.state.projectBio}</p> 
                        </div>
                      </div>
                    </div>
                </Modal>
            </section>
          </div>
      </div>
    );
  }
}

export default DigitalPrintCards;