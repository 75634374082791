//this is a parent - only imported in App.js with path: careers/:name

import React, { Component } from 'react';
import axios from 'axios';
import DocumentViewer from '../pages-other/DocumentViewer';

class IndividCareer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      career: {}
    };
  }

  componentDidMount() {
    const name = this.props.props.match.params.name;
    axios.post('/career/getIndividCareer/'+name, {
      data: name
    }).then(data => {
      console.log('data in getIndividCareer: ', data);
        this.setState({
          career: data.data.career,
        })
      }).catch(err => {
        console.log('err in /getIndividCareer: ', err);
      })
  }

  render() {
    if (!this.state.career) {
      return (
        <p>loading</p>
      )
    } else {
      return (
        <span style={{width: '100%'}}>
          <div className='marginBottomTwenty' onClick={this.change}>
            <div className='individPageHeader' onClick={this.change}>
              <h1 className='topTitle individPageTitle'>{this.state.career.position}</h1>
              <p className='externalUrlHolder'>{this.state.career.url ? this.state.career.url.map((data, index) => (
                <span className='programData'><a href={data.url} target='_blank'  rel="noopener noreferrer" className='link teal'>{data.name}</a></span>)) : null
              }</p>
              <p className='preLine' style={{marginBottom: '20px'}}>{this.state.career.description}</p>
              {this.state.career.documentUrl ? <DocumentViewer documentUrl={this.state.career.documentUrl}/> : null}
            </div>
          </div>
          <a className='link teal goBack' href='/about#careers' alt='back to about page'>back to about page</a>
        </span>
      )
    }
  }
}

export default IndividCareer;
