import React, { Component } from 'react';
import './translationInterpretation-Description.css';

export default class TranslationInterpretationDescription extends Component {
  render() {
    return(
      <div id="translationInterpretation-description-container">
        <div class="translationInterpretation-description-content-container">
          <p>Language Translation and Interpretation programs for CTE are continuing to grow both in popularity and need as an increasing number of jobs in health, law, government, and social services demand these skills. We had the opportunity to partner with a local program for high school students in 2020 to help provide support materials, necessary equipment, and access to additional training opportunities for students. We believe that language services are an imperative part of the workforce and look forward to finding additional ways to support learners and instructors in this growing field.</p>
          <p>Check back for more information coming soon.</p>
        </div>
      </div>
    );
  }
}