import React, { Component } from 'react';
import './Everyday-Title.css';

export default class EverydayTitle extends Component {
  render() {
    return(
      <div id="everyday-artwork-title-container">
        <div class="everyday-artwork-title-content-container">
          <h4>Everyday Unseen Gallery</h4>
          <h2>Student Artwork</h2>
        </div>
      </div>
    );
  }
}