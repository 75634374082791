import React, { Component } from 'react';
import './adolescentsLandingPage-Header.css';

export default class AdolescentsLandingPageHeader extends Component {
  render() {
    return(
      <div id="adolescentsLandingPage-header-container">
          <div class="adolescentsLandingPage-header-content-container">
            <h1>Financial Attitudes and Knowledge of Adolescents and Young Adults Study</h1>
          </div>
      </div>
    );
  }
}