import React, { Component } from 'react';
import KcptHeader from './kcpt-Header';
import KcptContent from './kcpt-Content';
import './kcpt.css';

export default class Kcpt extends Component {
  render() {
    return(
      <div id="kcpt-container">
        <KcptHeader />
        <KcptContent />
      </div>
    );
  }
}