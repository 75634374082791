import React, { Component } from 'react';
import ProgramsHeader from './Programs-Header';
import ProgramsContent from './Programs-Content';
import './Programs.css';

export default class Programs3 extends Component {
  render() {
    return(
      <div id="newPrograms-container">
        <ProgramsHeader />
        <ProgramsContent />
      </div>
    );
  }
}