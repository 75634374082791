import React, { Component } from 'react';
import './vrLandingPage-Header.css';

export default class VrLandingPageHeader extends Component {
  render() {
    return(
      <div id="vrLandingPage-header-container">
          <div class="vrLandingPage-header-content-container">
            <h1>Virtual Reality Game Reviews</h1>
          </div>
      </div>
    );
  }
}