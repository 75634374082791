import React, { Component } from 'react';
import VrLandingPageIntro from './vrLandingPage-Intro';
import VrLandingPageDescription from './vrLandingPage-Description';
import VrLandingPageLinks from './vrLandingPage-Links';
import NewFooter from '../../pages-other/NewFooter';
import './vrLandingPage-Content.css';

export default class VrLandingPageContent extends Component {
  render() {
    return(
      <div id="vrLandingPage-content-container">
        <VrLandingPageIntro />
        <VrLandingPageDescription />
        <VrLandingPageLinks />
        <NewFooter />
      </div>
    );
  }
}