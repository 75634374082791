import React, { Component } from 'react';
import VrResourcesHeader from './vrResources-Header';
import VrResourcesContent from './vrResources-Content';
import './vrResources.css';

export default class VrResources extends Component {
  render() {
    return(
      <div id="vrResources-container">
        <VrResourcesHeader />
        <VrResourcesContent />
      </div>
    );
  }
}