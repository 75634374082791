import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import './beyondBoundaries-Documentary.css';

export default class BeyondBoundariesDocumentary extends Component {
  render() {
    return(
      <div id="beyondBoundaries-documentary-video-container">
        <div class="beyondBoundaries-documentary-video-content-container">
          <div class="beyondBoundaries-documentary-videoCard-container">
            <ReactPlayer 
              url='https://youtu.be/GV6Ts0evczM'
              className='react-player'
              //   playing
              controls
              width='100%'
              height='100%'
            />
          </div>
        </div>
      </div>
    );
  }
}