import React from 'react';
import './creativityDesignPlay-EducationProgramming.css'; // You can create this CSS file for styling

const EducationProgramming = () => {
  return (
    <div className="creativityDesignPlayEducationProgramming-container">
      <div className="creativityDesignPlayEducationProgramming-content-container">
      <h2 className="creativityDesignPlayEducationProgramming-title">Education Programming</h2>
                <div className="creativityDesignPlayEducationProgramming-highlights-grid-container">
                  <div className="creativityDesignPlayEducationProgramming-highlights-image-container">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Creativity%2C+Design%2C+and+Play/Recording+Studio+Philanthropy+Story+Thumbnail.png" alt="a group of youth in recording studio together"/>
                  </div>
                    <div className="creativityDesignPlayEducationProgramming-highlights-text">
                      <h2><a className="creativityDesignPlayEducationProgramming-highlightsLink" href="https://www.foundry10.org/stories/recording-studio-opens-up-to-young-entrepreneurs-interested-in-music" target="_blank" rel="noopener noreferrer">Recording Studio Opens Up to Young Entrepreneurs Interested In Music</a></h2>
                      <p>SPIN’s Young Entrepreneur Project and the Recreational Psychoacoustics Lab collaborated to demystify the music recording process for high school students.</p>
                    </div>
                </div>
                <div className="creativityDesignPlayEducationProgramming-highlights-grid-container">
                  <div className="creativityDesignPlayEducationProgramming-highlights-image-container">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Creativity%2C+Design%2C+and+Play/What%E2%80%99s+the+Difference+Between+Gamification+and+Game-Based+Learning.png" alt="researcher explaining game based learning"/>
                  </div>
                    <div className="creativityDesignPlayEducationProgramming-highlights-text">
                      <h2><a className="creativityDesignPlayEducationProgramming-highlightsLink" href="https://www.foundry10.org/stories/whats-the-difference-gamification-vs-game-based-learning" target="_blank" rel="noopener noreferrer">What’s the Difference Between Gamification and Game-Based Learning?</a></h2>
                      <p>Summary:  Have you ever heard about gamification or game-based learning and wondered what the difference is between the two? Senior Researcher Travis Willingham Windleharth explores these concepts in a new video!</p>
                    </div>
                </div>
                <div className="creativityDesignPlayEducationProgramming-highlights-grid-container">
                  <div className="creativityDesignPlayEducationProgramming-highlights-image-container">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Creativity%2C+Design%2C+and+Play/Give+A+Numbers+Game.png" alt="teacher playing with number blocks to young child"/>
                  </div>
                    <div className="creativityDesignPlayEducationProgramming-highlights-text">
                      <h2><a className="creativityDesignPlayEducationProgramming-highlightsLink" href="https://www.foundry10.org/resources/give-a-number-game-guide" target="_blank" rel="noopener noreferrer">Give-A-Number Guide</a></h2>
                      <p>Summary: Discover what your 2- to 5-year-old knows about numbers! Created by researchers in our <a href="https://www.foundry10.org/conceptual-development-lab" target="_blank" rel="noopener noreferrer">Conceptual Development Lab</a>, this online guide will walk you and your child through the Give-a-Number Game, a research-based method that reveals your child’s number knowledge!</p>
                    </div>
                </div>
                <div className="creativityDesignPlayEducationProgramming-highlights-grid-container">
                  <div className="creativityDesignPlayEducationProgramming-highlights-image-container">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Creativity%2C+Design%2C+and+Play/Everyday+Unseen.jpg" alt="youth artist presenting their art piece"/>
                  </div>
                    <div className="creativityDesignPlayEducationProgramming-highlights-text">
                      <h2><a className="creativityDesignPlayEducationProgramming-highlightsLink" href="https://www.everydayunseen.com" target="_blank" rel="noopener noreferrer">Everyday Unseen</a></h2>
                      <p>Premiering in 2018, Everyday Unseen is an annual showcase for young visual and performing artists in Seattle, WA. The exhibition aims to highlight young voices in an exciting and empowering way by providing a platform that amplifies their voices and passion.</p>
                    </div>
                </div>
                <div className="creativityDesignPlayEducationProgramming-highlights-grid-container">
                  <div className="creativityDesignPlayEducationProgramming-highlights-image-container">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Creativity%2C+Design%2C+and+Play/Game+to+Grow+Partnership.jpg" alt="board game on table"/>
                  </div>
                    <div className="creativityDesignPlayEducationProgramming-highlights-text">
                      <h2><a className="creativityDesignPlayEducationProgramming-highlightsLink" href="https://medium.com/the-foundry10-voice/how-games-can-grow-our-capacity-for-connection-ca4b4fe8cd6f" target="_blank" rel="noopener noreferrer">Game to Grow Partnership</a></h2>
                      <p>How can collective activities like tabletop role-playing games help young people build their self-confidence, express themselves, understand their emotions, and otherwise develop critical social and emotional learning (SEL) skills? <a href="https://gametogrow.org/" target="_blank" rel="noopener noreferrer">Game to Grow</a>, a Seattle-based nonprofit, is committed to answering those questions by actively engaging and supporting youth, their parents/caregivers, and educators. foundry10 is proud of our continued partnership with Game to Grow, which recently included a collaborative research study on how virtual gaming circles can support youth SEL skill development.</p>
                    </div>
                </div>
                <div className="creativityDesignPlayEducationProgramming-highlights-grid-container">
                  <div className="creativityDesignPlayEducationProgramming-highlights-image-container">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Creativity%2C+Design%2C+and+Play/Lake+Stickney+Urban+Artworks+Murals.jpg" alt="mural of youth"/>
                  </div>
                    <div className="creativityDesignPlayEducationProgramming-highlights-text">
                      <h2><a className="creativityDesignPlayEducationProgramming-highlightsLink" href="https://medium.com/the-foundry10-voice/community-paint-days-bring-lake-stickney-elementarys-grizzly-way-to-life-f57c5851d085" target="_blank" rel="noopener noreferrer">Lake Stickney/Urban Artworks Murals</a></h2>
                      <p>foundry10 is proud to support Urban Artworks (UA) School-Based Programs which provide opportunities for students in kindergarten through grade 12 to work alongside teaching artists to install murals at their schools. For the past year, UA teaching artists and assistants have been working with students and staff at Lake Stickney Elementary in Lynnwood, Washington to add vibrant, welcoming murals to the school’s spaces.</p>
                    </div>
                </div>
                <div className="creativityDesignPlayEducationProgramming-highlights-grid-container">
                  <div className="creativityDesignPlayEducationProgramming-highlights-image-container">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Creativity%2C+Design%2C+and+Play/Art+and+Education+-+The+Value+of+High+Quality+Materials.jpg" alt="young student in art class"/>
                  </div>
                    <div className="creativityDesignPlayEducationProgramming-highlights-text">
                      <h2><a className="creativityDesignPlayEducationProgramming-highlightsLink" href="https://www.foundry10.org/philanthropy/art-and-education-the-value-of-high-quality-materials" target="_blank" rel="noopener noreferrer">Art and Education: The Value of High Quality Materials</a></h2>
                      <p>While many support the idea of creativity and value artistic expression for youth, funding for arts education in classrooms is often one of the first things to get cut. When art is removed from the academic curriculum, youth miss out. Not only does art provide vital outlets of creative expression for developing minds, it is also an important component of social and emotional learning and a powerful force in cultural awareness, validation, and confidence-building.</p>
                    </div>
                </div>
      </div>
    </div>
  );
};

export default EducationProgramming;
