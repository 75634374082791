import React, { Component } from 'react';
// import VrResourcesIntro from './vrResources-Intro';
import VrResourcesDescription from './vrResources-Description';
import VrResourcesLinks from './vrResources-Links';
import NewFooter from '../../../../pages-other/NewFooter';
import './vrResources-Content.css';

export default class VrResourcesContent extends Component {
  render() {
    return(
      <div id="vrResources-content-container">
        {/* <VrResourcesIntro /> */}
        <VrResourcesDescription />
        <VrResourcesLinks />
        <NewFooter />
      </div>
    );
  }
}