import React, { Component } from 'react';
// import CulinaryIntro from './culinary-Intro';
import CulinaryDescription from './culinary-Description';
import CulinaryLinks from './culinary-Links';
import NewFooter from '../../../pages-other/NewFooter';
import './culinary-Content.css';

export default class CulinaryContent extends Component {
  render() {
    return(
      <div id="culinary-content-container">
        {/* <CulinaryIntro /> */}
        <CulinaryDescription />
        <CulinaryLinks />
        <NewFooter />
      </div>
    );
  }
}