//imported from App.js as parent component w/ path: /programs
//children: none

import React, { Component } from 'react';
import './Programs-Cards.css';
import axios from 'axios';

class ProgramsCards extends Component {
  constructor(props) {
    super(props)
    this.state = {
      subjectAreas: []
    };
  }

  componentDidMount() {
    axios.get('/programs2/getSubjectAreas')
      .then(subjectAreas2 => {
        this.setState({
          subjectAreas: subjectAreas2.data
        })
      }).catch(err => {
        console.log('err in /getPrograms: ', err);
      })
  }

  render() {
    let innerWidth = window.innerWidth;
    function dynamicSort(property) {
      let sortOrder = 1;
      if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }
      return function (a,b) {
        if(sortOrder === -1){
          return b[property].localeCompare(a[property]);
        } else {
          return a[property].localeCompare(b[property]);
        }        
      }
    }

    const programList = this.state.subjectAreas;
    programList.sort(dynamicSort("name"));
    const listSubjectAreas = programList.map((records, index) => {
      return (
        <a href={'/programs/'+records.nameShort} className='link noUnderline subjectAreaContainer'>
          <img className='subjectAreaPhoto' src={innerWidth < 960 ? records.imageLocationSmall : records.imageLocation} alt={records.name}/>
          <div className='subjectAreaNameHolder'>
            <h1 className='subjectAreaName moon'>{records.name}</h1>
          </div>
          <div className='subjectAreaBriefDescriptionHolder'>
            <p className='subjectAreaBriefDescription moon'>{records.brief}</p>
          </div>
        </a>
      )
    })
    return (
      <div class="mainPrograms-pageContainer">
        <div class='mainPrograms-subjectAreaList'>
          {listSubjectAreas}
        </div>
      </div>
    );
  }
}

export default ProgramsCards;
