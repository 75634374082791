import React, { Component } from 'react';
import './threeDMixedMedia-header.css';

class ThreeDMixedMediaHeader extends Component {
  render() {
    return(
      <div id="threeDMixedMediaHeader-container">
          <div class="threeDMixedMediaImage-container">
            <div class="threeDMixedMedia-header-image">
              <h1>3D & Mixed Media</h1>
              <img src="https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/header/3D+%26+Mixed+Media+Header+2.jpg" alt="3d mixed media header"/>
            </div>
          </div>
      </div>
    );
  }
}

export default ThreeDMixedMediaHeader;