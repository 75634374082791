import React, { Component } from 'react';
import './healthWellBeing-Team.css';

export default class HealthWellBeingTeam extends Component {
  render() {
    return (
      <div className="healthWellBeingTeam-container">
        <div className="healthWellBeingTeam-content-container">
          <h2 className="healthWellBeingTeam-title">Meet the Team Leading This Work</h2>
          {/* <h2>Current Lab Members</h2> */}
          {/* <h2 className="healthWellBeingEducationTeam-titleContent-container"><span>Meet the Team Leading This Work</span></h2> */}
          <div class="healthWellBeingTeam-paragraph">
            {/* <h2>To put it simply, conceptual development means 'learning'</h2> */}
            <p>Supporting the physical and mental health of young people is vital for ensuring they can learn, grow, and fully participate as members of society. Our team seeks to identify and address gaps in health education, increase access to health services, and ensure students have what they need to grow into healthy and well adults.</p>
            <p>Recent and ongoing projects include philanthropic support for basic health measures in schools like <a href="https://www.foundry10.org/2022-health-ed-highlights" target="_blank" rel="noopener noreferrer">audiology and eye care</a>, our "<a href="https://www.foundry10.org/research/mindful-fibers-a-program-weaving-together-fiber-arts-and-mindfulness-practices" target="_blank" rel="noopener noreferrer">Mindful Fibers</a>" program combining mindfulness and fiber arts, and research on health challenges ranging from COVID-19 to concussion treatment.</p>
          </div>
          <ul className="healthWellBeingTeam-cardsList">
            <li>
              <a href="/about/team/hope-donato" className="healthWellBeingTeam-linkNew">
                <div className="healthWellBeingTeam-cardNew">
                  <div className="healthWellBeingTeam-card-imageNew">
                    <img src='https://f10-people.s3.us-west-2.amazonaws.com/hope-donato-headshot.png' width="100%" alt="young woman in glasses smiling"/>
                  </div>
                  <div className="healthWellBeingTeam-card-contentNew">   
                    <h2>Hope Donato</h2>
                    <p>Team Lead</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/kahle-gorzelsky" className="healthWellBeingTeam-linkNew">
                <div className="healthWellBeingTeam-cardNew">
                  <div className="healthWellBeingTeam-card-imageNew">
                    <img src='https://f10-people.s3.us-west-2.amazonaws.com/Re-edited+Team+Photos+230x300/Most+Recent/CHELSI-3.jpg' width="100%" alt="young person in patterned shirt smiling"/>
                  </div>
                  <div className="healthWellBeingTeam-card-contentNew">   
                    <h2>Kahle Gorzelsky</h2>
                    <p>Program Developer</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/naomi-green" className="healthWellBeingTeam-linkNew">
                <div className="healthWellBeingTeam-cardNew">
                  <div className="healthWellBeingTeam-card-imageNew">
                    <img src='https://f10-people.s3.us-west-2.amazonaws.com/Naomi+Green+Headshot.png' width="100%" alt="young woman in jean jacket smiling"/>
                  </div>
                  <div className="healthWellBeingTeam-card-contentNew">   
                    <h2>Naomi Green</h2>
                    <p>Executive Project Manager</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/rachael-hocevar" className="creativityDesignPlayTeam-linkNew">
                <div className="creativityDesignPlayTeam-cardNew">
                  <div className="creativityDesignPlayTeam-card-imageNew">
                    <img src='https://s3.us-west-2.amazonaws.com/craft-images/Team/Headshots/Alternate_Headshot_Rachael-Hocevar.jpg' width="100%" alt="woman in black tee smiling"/>
                  </div>
                  <div className="creativityDesignPlayTeam-card-contentNew">   
                    <h2>Rachael Hocevar</h2>
                    <p>Assistant Researcher</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/helen-lee" className="healthWellBeingTeam-linkNew">
                <div className="healthWellBeingTeam-cardNew">
                  <div className="healthWellBeingTeam-card-imageNew">
                    <img src='https://f10-people.s3.us-west-2.amazonaws.com/Re-edited+Team+Photos+230x300/Most+Recent/Helen+Lee.JPG' width="100%" alt="young woman in black shirt smiling"/>
                  </div>
                  <div className="healthWellBeingTeam-card-contentNew">   
                    <h2>Helen Lee</h2>
                    <p>Senior Researcher</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/janelle-salcedo" className="healthWellBeingTeam-linkNew">
                <div className="healthWellBeingTeam-cardNew">
                  <div className="healthWellBeingTeam-card-imageNew">
                    <img src='https://craft-images.s3.us-west-2.amazonaws.com/Team/Headshots/Staff-Headshot-Janelle-Salcedo-2024.png' width="100%" alt="young woman in blue sweater smiling"/>
                  </div>
                  <div className="healthWellBeingTeam-card-contentNew">   
                    <h2>Janelle Salcedo</h2>
                    <p>Research Coordinator</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/manrita-sidhu" className="healthWellBeingTeam-linkNew">
                <div className="healthWellBeingTeam-cardNew">
                  <div className="healthWellBeingTeam-card-imageNew">
                    <img src='https://f10-people.s3.us-west-2.amazonaws.com/Re-edited+Team+Photos+230x300/Most+Recent/Manrita.jpeg' width="100%" alt="woman in glasses smiling"/>
                  </div>
                  <div className="healthWellBeingTeam-card-contentNew">   
                    <h2>Manrita Sidhu</h2>
                    <p>Researcher</p>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}