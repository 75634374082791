import React, { Component } from 'react';
// import EverydayUnseen from './everyday-unseen/Everyday-Unseen';
import './Student-Highlights.css';

class StudentHighlights extends Component {
  render() {
    // const goToEverydayUnseen = () => {
    //     const newLocation = '/student-work/everyday-unseen';
    //     window.open(newLocation);
    // }
    return(
      <div id="Cards-Data2-Container">
        <div class="cardsList2-container">
        <ul id="cardsList2" class="events-list2">
          <li>
            <a href="/everyday-unseen-2018">
              <div class="student-work-card-container">
                <img src="https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/everyday-unseen+highlight.JPG" alt="everyday unseen gallery page" />
                <p id="Everyday Unseen" class="student-work-card-title">everyday unseen</p>
                <div id="Everyday Unseen" class="student-work-card-overlay"></div>
                <div class="student-work-card-button"><a id="Everyday Unseen" href="/student-work/everyday-unseen">GALLERY STORY</a></div>
              </div>
            </a>
          </li>
          <li>
            <a href="/short-run-2018">
              <div class="student-work-card-container">
                <img src="https://s3-us-west-2.amazonaws.com/f10-image/Short+Run/short+run+student+work+card.jpg" alt="short run web comic page" />
                <p class="student-work-card-title">short run</p>
                <div class="student-work-card-overlay"></div>
                <div class="student-work-card-button"><a href="/student-work/short-run">WEB COMIC</a></div>
              </div>
            </a>
          </li>
        </ul>
        </div>
      </div>
    );
  }
}

export default StudentHighlights;