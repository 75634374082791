import React from 'react';

// import ReactGA from 'react-ga';

const DocumentViewer = ({documentUrl}) => {
  // *AJ's Google Analytics Tracking*
  // const gaEventTag = (name, url) => {
  //   let locat = window.location.pathname;
  //   const isResearch = locat.includes('research');
  //   if (isResearch === true) {
  //     // console.log('name: ', name, ' url: ', url, ' -- location: ', window.location);
  //     ReactGA.event({
  //       category: 'Research Reports',
  //       action: 'Click',
  //       label: 'name: '+ name + ' -url: '+url
  //     });
  //   } else {
  //     console.log('not research');
  //   }
  // }

  let renderDocuments = documentUrl.map((document, index) => {
    if (document.url !== '') {
      return (
        <div className='documentViewerHolder'>
        {/* <div className='documentViewerHolder' onClick={() => gaEventTag(document.name, document.url)}>  */}
          {/* <a className='documentViewerLink link noUnderline' href={document.url} target='_blank'  rel="noopener noreferrer" alt={document.name}>
            <embed frameBorder="0" className='documentViewerEmbed' src={document.type === 'doc' ? 'https://s3-us-west-2.amazonaws.com/f10-misc/doc-icon.png' : document.thumbnailUrl} title={document.name}/>
          </a> */}
          <a className='documentViewerEmbed' 
            target={document.name} 
            href={document.url} 
            alt={document.name} 
            >
              <img class={document.name} alt={document.name} longdesc={document.name} src={document.type === 'doc' ? 'https://s3-us-west-2.amazonaws.com/f10-misc/doc-icon.png' : document.thumbnailUrl} width="130" height="200" />
              {/* {document.name}  */}
              <span class="documentViewerTitleNew">
                <div class="documentViewerTitleNew2">{document.name}</div>
              </span>
          </a>
          {/* <p className='documentViewerTitle'>
            <a href={document.url} alt={document.name} target="_blank"  rel="noopener noreferrer" className='link noUnderline'>{document.name}</a>
          </p> */}
        </div>
      )
    } else {
      return null
    }
  })
  if (documentUrl.length > 0) {
    return (
      <div className='getFromDbContainer'>
        <h3 className='getFromDbHeader orange'>Documents</h3>
        <div className='documentViewerContainer'>
          {documentUrl.length !== 0 ? renderDocuments : null}
        </div>
      </div>
    )
  } else {
    return (
      <p style={{display: 'none'}}></p>
    )
  }
}

export default DocumentViewer;
