import React, { Component } from 'react';
import './beyondBoundaries-Intro.css';

export default class BeyondBoundariesIntro extends Component {
  render() {
    return(
      <div id="beyondBoundaries-intro-container">
        <div class="beyondBoundaries-intro-content-container">
          {/* <div class="beyondBoundaries-intro-bold">
            <h3>At a Glance</h3>
          </div> */}
          <div class="beyondBoundaries-intro-normal">
            <p>Beyond Boundaries is foundry10's annual celebration of dance styles, cultures, and skills—featuring live performances by high school dance teams from all over the Greater Seattle area. Students choreograph and plan their own performances for this one-of-a-kind event. Professionals in the dance world also join the high schoolers on stage for exhibition pieces during the live show, and they lead workshops to help the youth learn and grow as dancers.</p>
          </div>
        </div>
      </div>
    );
  }
}