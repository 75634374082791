import React, { Component } from 'react';
import './digitalPrint-header.css';

class DigitalPrintHeader extends Component {
  render() {
    return(
      <div id="digitalPrintHeader-container">
          <div class="digitalPrintImage-container">
            <div class="digitalPrint-header-image">
              <h1>Digital & Print</h1>
              <img src="https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/header/Digital+%26+Print+Header+2.jpg" alt="digital print header"/>
            </div>
          </div>
      </div>
    );
  }
}

export default DigitalPrintHeader;