import React, { Component } from 'react';
import './carpentryLandingPage-Description.css';

export default class CarpentryLandingPageDescription extends Component {
  render() {
    return(
      <div id="carpentryLandingPage-description-container">
        <div className="carpentryLandingPage-description-content-container">
          <p>Hands-on learning is a crucial part of career and technical education. Our interest in carpentry and construction careers stems from our belief that student interaction with raw materials and tools—through the process of constructing and completing a project—is a foundational educational experience. Whether students are simply exploring and testing out ideas for a project or planning to pursue a career in carpentry and construction, time spent building and making with their hands is important and valuable. We are excited to learn more about this area of career and technical education and support student learning in new ways!</p>
        </div>
      </div>
    );
  }
}