import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import './Program.css';
import ProgramResearch from './Program-Research';
import Video from './Video';
import ProgramDocument from './Program-Document';
import ProgramTeam from './Program-Team';
import NewFooter from '../../pages-other/NewFooter';

import Error404Page from '../../pages-other/NewSite404/newSite404';

export default class Program extends Component {
  constructor(props) {
    super(props)
    this.state = {
      program: {},
      allPeople: []
    };
  }

  componentDidMount() {
    const name = this.props.props.match.params.name;
    axios.get('/programs/getOneProgram/'+name, {
      data: name
    }).then(data => {
        this.setState({
          program: data.data,
          allPeople: data.data.allPeople
        })
      }).catch(err => {
        console.log('err in /getPrograms: ', err);
      })
  }


  render() {
    if (this.state.program) {
      return (
        <div id="programProject-main-container">
          <Helmet>
            <title>{this.state.program.seoTitle}</title>
            <meta
              name="title"
              content={this.state.program.seoTitle}
            />
            <meta
              name="description"
              content={this.state.program.seoDescription}
            />
            <meta property="og:title" content={this.state.program.seoTitle} />
            <meta
              property="og:description"
              content={this.state.program.seoDescription}
            />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={this.state.program.seoUrl} />
            <link rel="canonical" href={this.state.program.seoUrl} />
            
            <script type="application/ld+json">
              {`
                {
                  "@context": "https://schema.org",
                  "@type": "WebPage",
                  "name": "${this.state.program.seoTitle}",
                  "description": "${this.state.program.seoDescription}",
                  "url": "${this.state.program.seoUrl}"
                }
              `}
            </script>
          </Helmet>
          {/* HEADER */}
          {/* <div class="programProject-header-container" style={{backgroundImage: "url(" + `${this.state.program.headerImageUrl}` + ")"}}> */}
          <div class="programProject-header-container" style={{backgroundImage: `url(${this.state.program.headerImageUrl})`}}>
            <div class="programProject-header-content-container">
              <h1>{this.state.program.name}</h1>
            </div>
          </div>
          {/* BODY */}
          <div id="programProject-main-content-container">
            {/* BRIEF & DOCUMENT */}
            {/* <div id="researchPost-briefDoc-container">
              <div class="researchPost-briefDoc-content-container">
                <div class="researchPost-brief-container">
                  <div class="researchPost-brief-description-container">
                    <p>{this.state.research.brief}</p>
                  </div>
                </div>
                <div class="researchPost-document-container">
                  <p>Explore the research here!</p>
                  {this.state.research.documentUrl ? <DocumentViewer documentUrl={this.state.research.documentUrl}/> : null}
                </div>
              </div>
            </div> */}
            {/* DESCRIPTION */}
            <div id="programProject-description-container">
              <div class="programProject-description-content-container">
                <p>{this.state.program.description}</p>
              </div>
            </div>
            {/* VIDEO */}
            <div id="programProject-video-container">
              <div class="programProject-video-content-container">
                <Video all={this.state.program.videoUrl} />
              </div>
            </div>
            {/* DOCUMENTS */}
            <div id="programProject-document-container">
              <div class="programProject-Cards-Data2-Container">
                <ProgramDocument all={this.state.program.documentUrl} />
              </div>
            </div>
            {/* RESEARCH */}
            <div id="programProject-research-container">
              <div class="programProject-research-content-container">
                <ProgramResearch all={this.state.program.allResearch} />
              </div>
            </div>
            {/* PROGRAM TEAM */}
            <ProgramTeam all={this.state.allPeople} />
            <NewFooter />
          </div>
        </div>
      )
    } else {
      return (
        <Error404Page />
      )
    }
  }
}
