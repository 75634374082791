import React from 'react';
import './healthWellBeing-EducationProgramming.css'; // You can create this CSS file for styling

const EducationProgramming = () => {
  return (
    <div className="healthWellBeingEducationProgramming-container">
      <div className="healthWellBeingEducationProgramming-content-container">
      <h2 className="healthWellBeingEducationProgramming-title">Education Programming</h2>
                <div className="healthWellBeingEducationProgramming-highlights-grid-container">
                  <div className="healthWellBeingEducationProgramming-highlights-image-container">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Health+and+Well-Being/Move+60.png" alt="move60 logo"/>
                  </div>
                  {/* <div className="healthWellBeingEducationProgramming-highlights-info-container">  */}
                    {/* <div className="healthWellBeingEducationProgramming-highlights-tags">
                      <div className="healthWellBeingEducationProgramming-highlights-hash">
                        <div className="youthHash-teal">REPORT</div>
                        <div className="youthHash-hash">#Drama</div>
                      </div>
                    </div> */}
                    <div className="healthWellBeingEducationProgramming-highlights-text">
                      <h2><a className="healthWellBeingEducationProgramming-highlightsLink" href="https://www.edmonds.wednet.edu/programs-services/community-health/move-60" target="_blank" rel="noopener noreferrer">Move 60!</a></h2>
                      <p>Move 60! is a before- and after-school exercise program for youth in grades 2-6. Move 60!'s goal is to increase opportunities for student activity and exercise outside of the school day. Our team is collaborating with them to help fund programming at six local elementary schools during the 2023-2024 school year.</p>
                    </div>
                    {/* <div className="healthWellBeingEducationProgramming-highlights-link">
                      <p>Dec 7th, 2021</p>
                      <a href='https://drive.google.com/file/d/1k3kbUQE1OC-aZ663sFm5mXOvqrP211yL/view?usp=sharing' className="healthWellBeingEducationProgramming-highlights-content-link" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-arrow-right"></i></a>
                    </div> */}
                  {/* </div> */}
                </div>
                <div className="healthWellBeingEducationProgramming-highlights-grid-container">
                  <div className="healthWellBeingEducationProgramming-highlights-image-container">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Health+and+Well-Being/Bellingham+School+District+Audiology+Van.png" alt="young child getting ear exam"/>
                  </div>
                  {/* <div className="healthWellBeingEducationProgramming-highlights-info-container">  */}
                    {/* <div className="healthWellBeingEducationProgramming-highlights-tags">
                      <div className="healthWellBeingEducationProgramming-highlights-hash">
                        <div className="youthHash-teal">REPORT</div>
                        <div className="youthHash-hash">#Drama</div>
                      </div>
                    </div> */}
                    <div className="healthWellBeingEducationProgramming-highlights-text">
                      <h2>Bellingham School District Audiology Van</h2>
                      <p>We helped Bellingham School District purchase a vehicle and initial equipment for an audiology van to provide mobile hearing tests and treatment for students across the Bellingham school district. The van will also travel into neighboring districts to assist students in the smaller schools with access to hearing testing and care.</p>
                    </div>
                    {/* <div className="healthWellBeingEducationProgramming-highlights-link">
                      <p>Dec 7th, 2021</p>
                      <a href='https://drive.google.com/file/d/1k3kbUQE1OC-aZ663sFm5mXOvqrP211yL/view?usp=sharing' className="healthWellBeingEducationProgramming-highlights-content-link" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-arrow-right"></i></a>
                    </div> */}
                  {/* </div> */}
                </div>
                <div className="healthWellBeingEducationProgramming-highlights-grid-container">
                  <div className="healthWellBeingEducationProgramming-highlights-image-container">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Health+and+Well-Being/Mukilteo+Equity+for+Student+Athletes+Initiative.png" alt="student athlete getting arm examined"/>
                  </div>
                  {/* <div className="healthWellBeingEducationProgramming-highlights-info-container">  */}
                    {/* <div className="healthWellBeingEducationProgramming-highlights-tags">
                      <div className="healthWellBeingEducationProgramming-highlights-hash">
                        <div className="youthHash-teal">REPORT</div>
                        <div className="youthHash-hash">#Drama</div>
                      </div>
                    </div> */}
                    <div className="healthWellBeingEducationProgramming-highlights-text">
                      <h2>Mukilteo Equity for Student Athletes Initiative</h2>
                      <p>We provided funding to Mukilteo School District Sport that supported physicals for the 2023-2024 academic year, including their 2023 Back-To-School Fair in August. These physicals were required for student athletes, and our funding removed the financial and access barriers for all students wishing to take part in the sports program, regardless of their family's resources.</p>
                    </div>
                    {/* <div className="healthWellBeingEducationProgramming-highlights-link">
                      <p>Dec 7th, 2021</p>
                      <a href='https://drive.google.com/file/d/1k3kbUQE1OC-aZ663sFm5mXOvqrP211yL/view?usp=sharing' className="healthWellBeingEducationProgramming-highlights-content-link" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-arrow-right"></i></a>
                    </div> */}
                  {/* </div> */}
                </div>
              {/* <div class="healthWellBeingEducationProgramming-publications-container">
                <div class="healthWellBeingEducationProgramming-publications-content-container">
                  <ul class="healthWellBeingEducationProgramming-publications-list">
                    <li>
                      <a href="/" target="_blank" rel="noopener noreferrer">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a>
                    </li>
                    <li>
                      <a href="/" target="_blank" rel="noopener noreferrer">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a>
                    </li>
                    <li>
                      <a href="/" target="_blank" rel="noopener noreferrer">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a>
                    </li>
                  </ul>
                </div>
              </div> */}
      </div>
    </div>
  );
};

export default EducationProgramming;
