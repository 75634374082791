import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import CenterHumanServicesHeader from './centerHumanServices-Header';
import CenterHumanServicesContent from './centerHumanServices-Content';
import './centerHumanServices.css';

export default class CenterHumanServices extends Component {
  render() {
    return(
      <div id="centerHumanServices-container">
        <Helmet>
          <title>foundry10 Center for Human Services Partnership</title>
          <meta
            name="title"
            content="foundry10 Center for Human Services Partnership"
          />
          <meta
            name="description"
            content="Supporting student mental health during COVID and beyond, foundry10 partners with schools to create safe spaces and reduce anxiety for youth."
          />
          <meta property="og:title" content="foundry10 Center for Human Services Partnership" />
          <meta
            property="og:description"
            content="Supporting student mental health during COVID and beyond, foundry10 partners with schools to create safe spaces and reduce anxiety for youth."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/center-for-human-partnership" />
          <link rel="canonical" href="https://www.foundry10.org/center-for-human-partnership" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Center for Human Services Partnership",
                "description": "Supporting student mental health during COVID and beyond, foundry10 partners with schools to create safe spaces and reduce anxiety for youth.",
                "url": "https://www.foundry10.org/center-for-human-partnership"
              }
            `}
          </script>
        </Helmet>
        <CenterHumanServicesHeader />
        <CenterHumanServicesContent />
      </div>
    );
  }
}