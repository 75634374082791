import React, { Component } from 'react';
import './Highlight-Title.css';

export default class HighlightTitle extends Component {
  render() {
    return(
      <div id="studentWork-highlightTitle-container">
        <div class="studentWork-highlightTitle-content-container">
          <h4>Student Highlights</h4>
          <h2>Events & Projects</h2>
        </div>
      </div>
    );
  }
}