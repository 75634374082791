import React, { Component } from 'react';
import './vrLandingPage-Intro.css';

export default class VrLandingPageIntro extends Component {
  render() {
    return(
      <div id="vrLandingPage-intro-container">
        <div class="vrLandingPage-intro-content-container">
          <div class="vrLandingPage-intro-bold">
            <h3>Potential in VR classroom applications</h3>
          </div>
          <div class="vrLandingPage-intro-normal">
            <p>Virtual and Augmented Reality are exciting platforms for games, art, and previously unimagined experiences. How can we bring it to classrooms?</p>
          </div>
        </div>
      </div>
    );
  }
}