import React, { Component } from 'react';
import ProgramsDescription from './Programs-Description';
import ProgramsCards from './Programs-Cards';
import NewFooter from '../../pages-other/NewFooter';
import './Programs-Content.css';

export default class ProgramsContent extends Component {
  render() {
    return(
      <div id="newPrograms-content-container">
        <ProgramsDescription />
        <ProgramsCards />
        <NewFooter />
      </div>
    );
  }
}