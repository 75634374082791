import React, { Component } from 'react';
import './vrResources-Description.css';

export default class VrResourcesDescription extends Component {
  render() {
    return(
      <div id="vrResources-description-container">
        <div class="vrResources-description-content-container">
          <p>Over the past few years, the foundry10 EdTech team partnered with educators across the nation to better understand barriers and successes of implementing VR in education settings. Bringing VR into a classroom can be a daunting task, so our team created resources to make that process easier for educators.</p>
          <p>These resources range from VR content reviews and stress tests to VR experiences and hardware lists. Our goal is to eliminate barriers for implementation. We hope these resources help you in your journey to successfully implement VR in your classroom.</p>
        </div>
      </div>
    );
  }
}