import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import CulinaryHeader from './culinary-Header';
import CulinaryContent from './culinary-Content';
import './culinary.css';

export default class Culinary extends Component {
  render() {
    return(
      <div id="culinary-container">
        <Helmet>
          <title>foundry10 Career and Technical Education: Culinary</title>
          <meta
            name="title"
            content="foundry10 Career and Technical Education: Culinary"
          />
          <meta
            name="description"
            content="Explore how we navigated remote learning challenges and innovations to support teachers and students in Culinary Arts CTE programs during the COVID-19 pandemic."
          />
          <meta property="og:title" content="foundry10 Career and Technical Education: Culinary" />
          <meta
            property="og:description"
            content="Explore how we navigated remote learning challenges and innovations to support teachers and students in Culinary Arts CTE programs during the COVID-19 pandemic."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/culinary" />
          <link rel="canonical" href="https://www.foundry10.org/culinary" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Career and Technical Education: Culinary",
                "description": "Explore how we navigated remote learning challenges and innovations to support teachers and students in Culinary Arts CTE programs during the COVID-19 pandemic.",
                "url": "https://www.foundry10.org/culinary"
              }
            `}
          </script>
        </Helmet>
        <CulinaryHeader />
        <CulinaryContent />
      </div>
    );
  }
}