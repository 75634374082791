import React, { Component } from 'react';
import './adolescentsLandingPage-Description.css';

export default class AdolescentsLandingPageDescription extends Component {
  render() {
    return(
      <div id="adolescentsLandingPage-description-container">
        <div class="adolescentsLandingPage-description-content-container">
        
          <p>Despite increased attention on financial literacy education, there is still a lack of research about how young people view and manage their finances during adolescence and young adulthood.</p>
          
          <p>We interviewed and surveyed high schoolers and college students on their attitudes around personal finance, their financial knowledge, how they spend and save money, what types of information they received about finance in school, and what financial topics they think are interesting and useful. This study is just the first step that foundry10 is taking to better understand the financial education needs of youth with Washington State.</p>

        </div>
      </div>
    );
  }
}