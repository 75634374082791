import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import OurStoryHeader from './OurStory-Header';
import OurStoryContent from './OurStory-Content';
import './OurStory.css';

export default class OurStory extends Component {
  render() {
    return(
      <div id="ourStory-container">
        <Helmet>
          <title>How foundry10 Started: Our Story</title>
          <meta
            name="title"
            content="How foundry10 Started: Our Story"
          />
          <meta
            name="description"
            content="Discover how foundry10 started expanding ideas about learning and creating direct value for youth as an education research organization."
          />
          <meta property="og:title" content="How foundry10 Started: Our Story" />
          <meta
            property="og:description"
            content="Discover how foundry10 started expanding ideas about learning and creating direct value for youth as an education research organization."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/our-story" />
          <link rel="canonical" href="https://www.foundry10.org/our-story" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "AboutPage",
                "name": "How foundry10 Started: Our Story",
                "description": "Discover how foundry10 started expanding ideas about learning and creating direct value for youth as an education research organization.",
                "url": "https://www.foundry10.org/our-story"
              }
            `}
          </script>
        </Helmet>
        <OurStoryHeader />
        <OurStoryContent />
      </div>
    );
  }
}