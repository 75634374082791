import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import './traditionalArt-cards.css';

class TraditionalArtCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
        visible: false,
        name: '',
        projectTitle: '',
        modalProjectTitle: '',
        studentBio: '',
        projectBio: '',
        cardImg: '',
        userImg: '',
        imgUrl: '',
        altText: ''
    }
  }
  
  openModal() {
    this.setState({
        visible: true
    });
  }

  closeModal() {
    this.setState({
        visible: false
    });
  }

  render() {
  const finalCards = [
    {
      'name': 'Anakin Saephanh',
      'projectTitle': 'Melodrama',
      'modalProjectTitle': 'Melodrama',
      'studentBio': 'Anakin Saephanh is a Senior hailing from Cleveland High School. Mainly invested with painting and drawing, he comes from a background taught from the internet rather than in a traditional setting. His paintings aim to range from being an escapist fantasy, to being directly reflective on personal life. After graduating in Spring of 2018, Anakin has set his sights towards attending School of the Art Institute of Chicago.',
      'projectBio': 'There are two different meanings of the piece, the first being its original intention, and the second being its influence while it was painted. Although, when painting the piece there is a second meaning referring to absolute totality. This was made in high school, and in high school there is teenage melodrama.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/traditional+art/Anakin+Saephanh.png',
      'altText': 'Abstract, pointillism oil painting with a dramatic, swirling, vertical color gradient from blue to pink.'
    },
    {
      'name': 'Ardelle Ning',
      'projectTitle': 'Street In Fukuoka',
      'modalProjectTitle': 'Street In Fukuoka',
      'studentBio': 'Ardelle Ning is a senior at Shorewood High School in Shoreline, WA. She is enrolled in AP Studio Art at Shorecrest High School. Ning’s AP Concentration is street scenes from around the world, focusing on the juxtaposition of old world/modern area architecture found in today’s urban settings. Ning, having grown up in a small town, is inspired by her travels abroad and hopes to bring scenes of foreign countries to those viewing her art as well as showcase the diversity found in today’s developing world. After graduating this June, Ardelle intends to study international relations and possibly minor in studio art/design.',
      'projectBio': 'Street scene in Fukuoka, Japan.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/traditional+art/Ardelle+Ning.png',
      'altText': 'Photorealistic, grayscale drawing of a residential street scene with large power lines and mountains in the background.'
    },
    {
      'name': 'Audrey Kang',
      'projectTitle': 'Kaya',
      'modalProjectTitle': 'Kaya',
      'studentBio': 'Audrey Kang is a senior at Newport High School in Bellevue, WA. Oil is messy and complicated. However, it’s also a medium that allows for and facilitates the type of experimentation Audrey has come to associate with my work. Having worked with this medium for four years, she is able to play with the texture of oil paint, knowing she can wash it down with gamsol or build it up with a thick consistency.',
      'projectBio': 'Based on a picture of my friend Kaya, this piece demonstrates ability to render unconventionally yet aesthetically. It was an artistic breakthrough in that the process of experimentation is shown by leaving raw canvas, using uncomfortably large brushes, and leaving it somehow “unfinished” as an artistic choice.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/traditional+art/Audrey+Kang.png',
      'altText': 'Oil painting of a young person with long, dark hair and a white blouse standing against abstract flowers and vines.'
    },
    {
      'name': 'Candace Chang',
      'projectTitle': 'Flying Seal',
      'modalProjectTitle': 'Flying Seal',
      'studentBio': 'Candace Chang is a senior at Thomas Jefferson High School. She mainly uses acrylic paint to depict elements connecting to nature. She likes to emphasize the beauty and importance of the natural world in her works.',
      'projectBio': 'This painting depicts a seal I saw at the zoo. I wanted to display the grace and power of the animal.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/traditional+art/Candance+Chang.png',
      'altText': 'Abstract acrylic painting in blue tones. A seal gracefully swims underwater through a large chasm.'
    },
    {
      'name': 'Cathy Tran',
      'projectTitle': 'PNW Triad',
      'modalProjectTitle': 'PNW Triad',
      'studentBio': 'Cathy Tran is a senior at Thomas Jefferson High School. She primarily uses acrylic paint to create landscapes inspired by popular sight around the world. Through art classes, she has also explored different mediums including pottery and photography. She will be attending the University of Washington on the Fall.',
      'projectBio': 'The PNW Triad was painted using acrylics on canvas. It depicts the three pillars that contribute to the Pacific Northwest’s appeal: the mountains, the forest, and the ocean.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/traditional+art/Cathy+Tran.png',
      'altText': 'Painting of a snowy mountain range lined with black trees. Yellow brushstrokes highlight the sun hitting the peaks.'
    },
    {
      'name': 'Ellie Works',
      'projectTitle': 'DREAMT',
      'modalProjectTitle': 'DREAMT',
      'studentBio': 'Ellie Works is a freshman at Cleveland High School and will graduate in 2021. Her artwork is often the style of short graphic novels or paintings. Ellie aims to create stories and paintings that give a sense of dreaminess, and that point out the sometimes surreal moments in life. Ellie is self-taught and predominantly works in water-color or ink.',
      'projectBio': 'Dreamt is a watercolor painting with pencil details. This piece, and many others I make, are based off of daydreams I have. Usually the characters in my paintings have a story. She is happily and unknowingly floating along, and just getting by.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/traditional+art/Ellie+Works.png',
      'altText': 'Cartoon figure with long, dark hair sleeps curled on a bed sinking into water with fish swimming around it.'
    },
    {
      'name': 'Gabriel Escobedo',
      'projectTitle': 'Her Eyes',
      'modalProjectTitle': 'Her Eyes',
      'studentBio': 'Gabriel is a senior at Thomas Jefferson High School. He paints in both acrylic and oil. He loves art and seeks to pursue painting in college.',
      'projectBio': 'Acrylic portrait of a woman on linen and mounted on foam board. The linen emphasizes the details in the womans face',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/traditional+art/Gabriel+Escobedo.png',
      'altText': "Portrait of a woman's face in warm white, orange, and brown tones against a navy blue background."
    },
    {
      'name': 'Irene Jin',
      'projectTitle': 'Untitled: 2',
      'modalProjectTitle': 'Untitled: 2',
      'studentBio': 'Irene Jin is a sophomore at Newport High School in Bellevue, WA. She has always been interested in experimentation with a variety of 2D mediums of art. Her art is largely influenced by different types of illustration and the idea of storytelling through her art.',
      'projectBio': 'This piece specifically addresses the idea of how everything is bound to change. Untitled: 2 intends to illustrate how although the trees lose their leaves and the ground becomes barren, unable to change the robot girl attempts to reach for something, but it is not there anymore. This illustrates how in this ever-changing modern world, everything is developing at such a rapid pace that one doesn’t have the time to stop as time will continue to pass regardless.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/traditional+art/Irene+Jin.png',
      'altText': 'Abstract painting of a gray and black figure facing towards a bare-branched tree reaching toward the sun.'
    },
    {
      'name': 'Isa Lewis',
      'projectTitle': 'Religion',
      'modalProjectTitle': 'Religion',
      'studentBio': 'Isa Lewis is a junior at Issaquah High School. She focuses on portraits and experiments in color. Her subjects often highlight religious and mystical overtones. A self-taught painter, Lewis uses predominantly acrylic on canvas. Lewis views each painting as a learning experience and hopes to continue to use painting as a method of exploration.',
      'projectBio': '‘Tis a face in a nun’s habit with yellow abstract accents. The habit has a red cross. The face is purple.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/traditional+art/Isa+Lewis.png',
      'altText': 'Abstract portrait of a purple face in a pale pink and blue habit with yellow graphic lines bordering it.'
    },
    {
      'name': 'Isabel Warn',
      'projectTitle': 'After the Flood',
      'modalProjectTitle': 'After the Flood',
      'studentBio': 'Isabel Warn is a sophomore at Summit Public School: Sierra in Seattle, WA. She will graduate in the spring of 2020 and plans to attend Digipen Institute of Technology in Redmond, WA. There she plans to pursue her BA in digital art and animation. Isabel’s artwork has been featured at school and she is currently enrolled in Urban Artworks, a course offered at her school that allows students to help design a mural for the school and teaches about other public art. Her work is heavily influenced by calming atmospheres and whimsical fairy tales.',
      'projectBio': 'This piece depicts a scene underwater where a mermaid boy is discovering the Seattle Space Needle underwater. There is an Orca whale and a Stingray swimming with him, and plant life surrounding them.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/traditional+art/Isabel+Warn.png',
      'altText': 'Watercolor drawing of an underwater scene where a mermaid boy and orca look at the Seattle Space Needle.'
    },
    {
      'name': 'Jey Vargas',
      'projectTitle': 'Wires',
      'modalProjectTitle': 'Wires',
      'studentBio': 'Jey Vargas is a senior at Thomas Jefferson High School. Jey’s artwork depicts his multiple identities. He is a transgender, queer, Mexican boy, and uses art as a way to navigate his culture, identity, showcase his triumphs, and discuss the issues he and others in marginalized communities face.',
      'projectBio': 'This piece consists of the hanging or floating above in the forest. There are “wires” hanging down, dangling around me. I made this shortly after my first suicide attempt. I was depressed, and was at my last point. I made this as a way to reflect at my time in the hospital. I made this as a way to reflect and cope with my emotions.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/traditional+art/Jey+Vargas.png',
      'altText': 'Painting of a young person with dark undereye circles whose arms are being entwined by vines hanging from tall, mossy trees.'
    },
    {
      'name': 'Julia Neils',
      'projectTitle': 'La Succette',
      'modalProjectTitle': 'La Succette',
      'studentBio': 'When you look at this, what do you see? Do you see a girl with dark eyes and hair as wild as her face? Or do you see a bright artistically colored __ of sugar and sweetness? Julia Neils hoped to show the power of contrast. How by using contrast and juxtaposition one element can completely consume a piece.',
      'projectBio': 'This piece used a mixture of graphite and colored pencil for the lollipop. Contrast plays a large part in creating dimension and overall curiosity incorporated into the piece.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/traditional+art/Julia+Neils.png',
      'altText': 'Grayscale photorealistic portrait of a young person with dark eyes and curly hair sucking on a red lollipop.'
    },
    {
      'name': 'Lily Molloy',
      'projectTitle': 'Last Hope',
      'modalProjectTitle': 'Last Hope',
      'studentBio': 'Lily Molloy is a freshman at Thomas Jefferson High School in Auburn, WA. She is currently enrolled in graphic design but has taken three other art classes in the past. Her favorite style to sketch and paint is fantasy and gore. Her goal is to go to college and major in art history.',
      'projectBio': 'This is an acrylic painting of the one last beautiful thing left in a sea of hate and fear. I made a stencil for the turtle shape and free handed the rest of the painting. I wanted to show that even in times of loss or sorrow there can still be something beautiful and great left in your life.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/traditional+art/Lily+Molloy.png',
      'altText': 'Acrylic painting of a sea turtle framed with gold and surrounded by ominous, dark figures creeping towards it.'
    },
    {
      'name': 'Mabel Baumgardner',
      'projectTitle': 'Dissolve',
      'modalProjectTitle': 'Dissolve',
      'studentBio': 'Mabel Baumgardner is a senior at Garfield High School. They’re currently enrolled in AP Studio Art and take great joy in experimenting with different mediums. Although they usually like the precision of colored pencils and drypoint etches, they’ve challenged themselves this year with working in oil paint more. They’ve been very successful with the help and resources they’ve received at foundry10.',
      'projectBio': 'Dissolve in an oil piece that features an old woman floating and half dissolving into a pool of water. The piece is meant to convey how with old age, people lose parts of themselves whether it be their physical strength or mental capabilities. The woman is smiling while her body sinks into the water to represent how people undergoing this process of decomposition might be oblivious to their situations or putting on a smile to hide the pain of their degeneracy.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/traditional+art/Mabel+Baumgardner.png',
      'altText': 'Post-impressionist style painting of a smiling older woman sinking into water.'
    },
    {
      'name': 'Madison Kim & Rachel Ho',
      'projectTitle': 'Festival Night',
      'modalProjectTitle': 'Festival Night',
      'studentBio': 'Rachel and Madison are juniors at Thomas Jefferson High School in Federal Way, WA. They are graduating in 2019 and plan to transfer to 4 year colleges. This painting was inspired by Japanese festivals and hopes to make viewers feel happy and relaxed. Their goals are to improve their creativity by trying different techniques and improving when something doesn’t work out.',
      'projectBio': 'Festival night market in Japan.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/traditional+art/Madison+Kim+%26+Rachel+Ho.png',
      'altText': 'Birds-eye-view of a night market on a lake shore. Orange lanterns hang over multiple stalls with people shopping.'
    },
    {
      'name': 'Rey Daoed',
      'projectTitle': 'The Story of Me',
      'modalProjectTitle': 'The Story of Me',
      'studentBio': 'Rey is a young high school artist with disability, autistic and couldn’t speak. He speaks with an iPad or through his handwriting. Rey has motor challenges, which makes it hard for him to write. He wants to paint more to let that feeling to free him. Through his paintings, hopefully, people can see what he sees, how his life is and how everything is beautiful and worth living.',
      'projectBio': 'The faint window is somewhat of a desirable object for an autistic person. The bottom left scene was how I used to be, before I have language. When my dad dressed me up that was when I knew we were going out. The hooded person is the future me as I want to see where my life will take me.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/traditional+art/Rey+Daoed.png',
      'altText': 'Abstract triptych in neutral tones with pops of blue showing a child and their parents, teens socializing, and a hooded figure.'
    },
    {
      'name': 'Savannah Johnson',
      'projectTitle': 'Flower Crown',
      'modalProjectTitle': 'Flower Crown',
      'studentBio': 'Savannah Johnson goes to Nathan Hale High School and will graduate in spring 2019. Johnson plans to go to college and study illustration and environmental science. She works mostly in ink drawings and dabbles in watercolor and acrylic paintings. She hopes to portray the beauty of people who don’t fit in the traditional sense of beauty.',
      'projectBio': 'This is an ink piece drawn with micron pen. The piece was drawn entirely freehand.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/traditional+art/Savannah+Johnson.png',
      'altText': "Black and white 3/4 portrait drawing of the back of a young person's head. Their braided hair becomes a flower bouquet."
    },
    {
      'name': 'Teddy Avestruz',
      'projectTitle': 'PAC',
      'modalProjectTitle': 'PAC',
      'studentBio': 'Theomatic (Teddy Avestruz), is a Filipino American Rapper, Producer, Dj, and visual artist based in South Seattle, WA. He has been performing as a Rapper and DJ for over 4 years. Theomatic’s mission is to educate the world and tell his story through Hip Hop.',
      'projectBio': 'I was raised on 2pac. When I immigrated to Seattle from the Philippines as a child, I experienced a huge cultural shift. I felt like an outsider. That changed when my grandfather gave me and my older brother his old Magnavox Boombox. Through that, we discovered Hip Hop. I discovered 2pac within that boombox and throughout all the trials and tribulations since then, 2pac was one of the main voices that got me through them.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/traditional+art/Tedy+Avestruz.png',
      'altText': 'Portrait of rapper 2pac outlined in black on a red background.'
    },
    {
      'name': 'Tiffany Howell',
      'projectTitle': 'Caretaker of the Aviary',
      'modalProjectTitle': 'Caretaker of the Aviary',
      'studentBio': 'Tiffany Howell, age 18, is an illustrative artist based in Tacoma, WA, and presently a senior at The Tacoma School of the Arts. Throughout their life, they have been inspired by the natural world and the human form, working with ink, watercolor, acrylic, and linoleum carving to capture the raw beauty of organic form.',
      'projectBio': 'Printed ink on canvas with acrylic overlay. Unframed with two wood dowels.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/traditional+art/Tiffany+Howell.png',
      'altText': "Ink on canvas portrait of a figure with a bare human chest and a bird's head in a fuzzy coat."
    },
    {
      'name': 'Tony Walters',
      'projectTitle': 'Tyrannosaurus West',
      'modalProjectTitle': 'Tyrannosaurus West',
      'studentBio': 'We were told to create a still life/perspective project for class. I liked the perspective idea, and there were dinosaurs involved, so I said, “why not put them on a train in the middle of the desert?” It made for a pretty interesting piece, but not to the quality I had hoped. I may choose to recreate it in the future.',
      'projectBio': 'This piece has no hidden meaning. There is no deep message behind it. It is simply what happens when you let an artist break the rules. You get a bunch of dinosaurs balancing on top of a train in the middle of nowhere.',
      'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/traditional+art/Tony+Walters.png',
      'altText': 'Cartoon drawing of colorful dinosaurs atop train cars on parallel tracks split apart by a chasm in the middle of the desert.'
    }
  ];

  const allCardData2 = finalCards.map((records, index) => (
    <li 
      key={index} 
      onClick={() => {
      this.openModal();
      this.setState({
        name: records.name,
        projectTitle: records.projectTitle,
        modalProjectTitle: records.modalProjectTitle,
        studentBio: records.studentBio,
        projectBio: records.projectBio,
        cardImg: records.cardImg,
        userImg: records.userImg,
        imgUrl: records.imgUrl,
        altText: records.altText
      });
    }}>
      <div class="final-cards-container">
        <img src={records.cardImg} alt={records.altText} />
        <p id={records.name} class="final-cards-title">{records.name}</p>
        <div id={records.name} class="final-cards-overlay"></div>
        <div class="final-cards-button"><span id={records.name} class="final-cards-popup-title">{records.projectTitle}</span></div>
      </div>
    </li>
  ));
    return (
      <div id="Cards-Data2-Container">
        <div class="cardsList2-container">
        <ul id="cardsList2" class="events-list2">
          {allCardData2}
        </ul>
        <section>
                <Modal 
                    visible={this.state.visible}
                    width="93%"
                    height="80%"
                    effect="fadeInDown"
                    onClickAway={() => this.closeModal()}
                >
                    <div class="art-close-container">
                      <div class="art-popup-close">
                        <button onClick={() => this.closeModal()}>
                          <img src="https://s3-us-west-2.amazonaws.com/f10-image/X-icon.png" alt="X close tag"/>
                        </button>
                      </div>
                    </div>
                    <div id="artModal-container">
                      <div class="artModal-content-container">
                        <div class="artModal-image-container">
                         <img src={this.state.cardImg} alt={this.state.altText}></img>
                        </div>
                        <div class="artModal-description-container">
                          <h1>{this.state.name}</h1>
                          <p>{this.state.studentBio}</p>
                          <h2>{this.state.modalProjectTitle}</h2>                
                          <p>{this.state.projectBio}</p> 
                        </div>
                      </div>
                    </div>
                </Modal>
            </section>
          </div>
      </div>
    );
  }
}

export default TraditionalArtCards;