import React, { Component } from 'react';
import RipplingWidget from './Rippling-Widget';
import './Current-Opportunities.css';

export default class CurrentOpportunities extends Component {

  render() {

    return(
      <div id="currentOpportunities-container">
        <div class="currentOpportunities-content-container">
          <div class="currentOpportunities-content-title">
            <h2>Current Opportunities</h2>
          </div>
          {/* <p>We're always looking to meet new talented people. Want to let us know how awesome you are for a future opportunity or one of our current open roles? If you think you're a good fit for foundry10, please apply and we will reach out to you as soon as we can.</p> */}
          <div class="currentOpportunities-links-container">
            {/* <RipplingWidget /> */}
            {/* <h4>Please check back again soon.</h4> */}
            <p><a href="https://recruiting.paylocity.com/recruiting/jobs/Details/2651913/Foundry10/Communications-Manager" rel='noopener noreferrer' target='_blank'>➜ Communications Manager</a></p>
            {/* <p><a href="/careers/philanthropy-director">➜ Director of Community and Philanthropic Relationships</a></p> */}
            {/* <p><a href="/careers/digital-content-writer">➜ Digital Content Writer/Editor (Temp-to-Hire)</a></p> */}
            {/* <p><a href="/careers/marine-science-undergraduate-intern">➜ Marine Science Undergraduate Intern</a></p> */}
            {/* <p><a href="/careers/senior-social-science-researcher">➜ Senior Social Science Researcher</a></p> */}
            {/* <p><a href="/careers/operations-manager">➜ Office Operations Manager</a></p> */}
            {/* <p><a href="/careers/program-coordinator">➜ Program Coordinator</a></p> */}
            {/* <p><a href="/careers/communications-director">➜ Director of Communications and Storytelling</a></p> */}
            {/* <p><a href="/careers/temp-to-hire-writer">➜ Temp-to-Hire Writer</a></p> */}
            {/* <p><a href="/careers/digital-audio-program-developer">➜ Digital Audio Program Developer</a></p> */}
            {/* <p><a href="/careers/digital-audio-teaching-artist">➜ Digital Audio Teaching Artist</a></p> */}
            {/* <p><a href="/careers/f10-gap-year-internship-program">➜ f10 Gap Year Internship Program</a></p> */}
            {/* <p><a href="/careers/full-stack-web-developer">➜ Full Stack Web Developer</a></p> */}
            {/* <p><a href="/careers/graduate-research-intern">➜ Graduate Research Intern</a></p> */}
            {/* <p><a href="/careers/hr-assistant">➜ Human Resources Assistant & Receptionist</a></p> */}
            {/* <p><a href="/careers/marine-science-education-researcher">➜ Marine Science Education Researcher</a></p> */}
            {/* <p><a href="/careers/social-media-associate">➜ Part-Time Social Media Associate (Contract)</a></p> */}
            {/* <p><a href="/careers/people-and-culture-strategist">➜ People and Culture Strategist</a></p> */}
            {/* <p><a href="/careers/research-coordinator">➜ Research Coordinator</a></p> */}
          </div>
        </div>
      </div>
    );
  }
}